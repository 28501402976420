/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import Decoder from "../../../../../decoding";
import Address from "../../../../../models/Address";
import StateObject from "../../../../../models/State/StateObject";
import { validateDecimal, validateInteger } from "../../../../../tools";

export default class WH4 extends StateObject{
    fullName;
    ssnOrITIN;
    homeAddress;
    city;
    state;
    zipCode;
    countyOfResidence;
    countyOfEmployment;
    countiesEffectiveNextYear;
    entitledExemption;
    marriedExemption;
    dependentExemptions;
    olderThan65;
    legallyBlind;
    spouseOlderThan65;
    spouseLegallyBlind;
    entitledExemptionForDependents;
    entitledExemptionForDependentsFirstTime;
    entitledExemptionForAdoptedDependents;
    additionalStateWitholding;
    additionalCountyWitholding;
    signed;

    constructor(
        fullName,
        ssnOrITIN,
        homeAddress,
        city,
        state,
        zipCode,
        countyOfResidence,
        countyOfEmployment,
        countiesEffectiveNextYear,
        entitledExemption,
        marriedExemption,
        dependentExemptions,
        olderThan65,
        legallyBlind,
        spouseOlderThan65,
        spouseLegallyBlind,
        entitledExemptionForDependents,
        entitledExemptionForDependentsFirstTime,
        entitledExemptionForAdoptedDependents,
        additionalStateWitholding,
        additionalCountyWitholding,
        signed,
    ){
        super();
        this.fullName = fullName;
        this.ssnOrITIN = ssnOrITIN;
        this.homeAddress = homeAddress;
        this.city = city;
        this.state = state;
        this.zipCode = zipCode;
        this.countyOfResidence = countyOfResidence;
        this.countyOfEmployment = countyOfEmployment;
        this.countiesEffectiveNextYear = countiesEffectiveNextYear;
        this.entitledExemption = entitledExemption;
        this.marriedExemption = marriedExemption;
        this.dependentExemptions = dependentExemptions;
        this.olderThan65 = olderThan65;
        this.legallyBlind = legallyBlind;
        this.spouseOlderThan65 = spouseOlderThan65;
        this.spouseLegallyBlind = spouseLegallyBlind;
        this.entitledExemptionForDependents = entitledExemptionForDependents;
        this.entitledExemptionForDependentsFirstTime = entitledExemptionForDependentsFirstTime;
        this.entitledExemptionForAdoptedDependents = entitledExemptionForAdoptedDependents;
        this.additionalStateWitholding = additionalStateWitholding;
        this.additionalCountyWitholding = additionalCountyWitholding;
        this.signed = signed;
    }


    static initDefault(){
        return new WH4(
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            false,
            '',
            '',
            '',
            false,
            false,
            false,
            false,
            '',
            '',
            '',
            '',
            '',
            false,
        )
    }

    static initFromData(document, userData){
        const firstName = userData.firstName ?? '';
        const middleName = userData.middleName ? `${userData.middleName} ` : '';
        const lastName = userData.lastName ?? '';
        const userAddress = Address.decodeNonStrict(userData.address);

        return new WH4(
            document.fullName ?? `${firstName} ${middleName}${lastName}`,
            document.ssnOrITIN ?? "",
            document.homeAddress ?? `${userAddress.thoroughfare} ${userAddress.premise}`,
            document.city ?? userAddress.locality,
            document.state ?? userAddress.administrativeArea,
            document.zipCode ?? userAddress.postalCode,
            document.countyOfResidence ?? '',
            document.countyOfEmployment ?? '',
            document.countiesEffectiveNextYear ?? false,
            document.entitledExemption ?? '',
            document.marriedExemption ?? '',
            document.dependentExemptions ?? '',
            document.olderThan65 ?? false,
            document.legallyBlind ?? false,
            document.spouseOlderThan65 ?? false,
            document.spouseLegallyBlind ?? false,
            document.entitledExemptionForDependents ?? '',
            document.entitledExemptionForDependentsFirstTime ?? '',
            document.entitledExemptionForAdoptedDependents ?? '',
            document.additionalStateWitholding ?? '',
            document.additionalCountyWitholding ?? '',
            false,
        )
    }

    subtotalAgeBlind(){
       return this.olderThan65 + this.legallyBlind + this.spouseOlderThan65 + this.spouseLegallyBlind;
    }

    totalExemptions(){
        return validateInteger(this.entitledExemption) + validateInteger(this.marriedExemption) + validateInteger(this.dependentExemptions) + this.subtotalAgeBlind();
    }

    encode(document, base64){
        return {
            ...document,
            base64: base64,
            fullName: this.fullName,
            ssnOrITIN: validateInteger(this.ssnOrITIN),
            homeAddress: this.homeAddress,
            city: this.city,
            state: this.state,
            zipCode: validateInteger(this.zipCode),
            countyOfResidence: this.countyOfResidence,
            countyOfEmployment: this.countyOfEmployment,
            countiesEffectiveNextYear: this.countiesEffectiveNextYear,
            entitledExemption: this.entitledExemption ? validateInteger(this.entitledExemption) : null,
            marriedExemption: this.marriedExemption ? validateInteger(this.marriedExemption) : null,
            dependentExemptions: this.dependentExemptions ? validateInteger(this.dependentExemptions) : null,
            olderThan65: this.olderThan65,
            legallyBlind: this.legallyBlind,
            spouseLegallyBlind: this.spouseLegallyBlind,
            spouseOlderThan65: this.spouseOlderThan65,
            entitledExemptionForDependents: this.entitledExemptionForDependents ? validateInteger(this.entitledExemptionForDependents) : null,
            entitledExemptionForDependentsFirstTime: this.entitledExemptionForDependentsFirstTime ? validateInteger(this.entitledExemptionForDependentsFirstTime) : null,
            entitledExemptionForAdoptedDependents: this.entitledExemptionForAdoptedDependents ? validateInteger(this.entitledExemptionForAdoptedDependents) : null,
            additionalStateWitholding: this.additionalStateWitholding ? validateDecimal(this.additionalStateWitholding) : null,
            additionalCountyWitholding: this.additionalCountyWitholding ? validateDecimal(this.additionalCountyWitholding) : null
        }
    }
}