/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import moment from 'moment'
import K4Page1 from '../../../../../assets/K4-1.png';
import K4Page2 from '../../../../../assets/K4-2.png';
import Signature from '../../../Signature/Signature'
import AbsoluteText from '../../AbsoluteText'
import { Document, Image, Page, Text } from '@react-pdf/renderer'
import { usdFormatter, validateDecimal } from '../../../../../tools'
import K4 from './K4';

type K4PdfProps = {
    k4: K4
    signature: Signature
}

export default function K4Pdf({k4, signature}: K4PdfProps) {

    return (
        <Document>
            <Page size='LETTER' style={{fontSize: 12}}>
                <Image src={K4Page1}/>
                <AbsoluteText y={93} x={100} text={k4.ssnFirst3} fontSize={16}/>
                <AbsoluteText y={93} x={162} text={k4.ssnMiddle2} fontSize={16}/>
                <AbsoluteText y={93} x={210} text={k4.ssnLast4} fontSize={16}/>

                <AbsoluteText y={130} x={35} text={k4.name}/>
                <AbsoluteText y={165} x={35} text={k4.mailingAddress}/>
                <AbsoluteText y={200} x={35} text={k4.city}/>
                <AbsoluteText y={200} x={230} text={k4.state}/>
                <AbsoluteText y={200} x={280} text={k4.zipCode}/>

                <AbsoluteText y={278} x={28} text={k4.exemptBox1 ? 'X' : ''} fontSize={9}/>
                <AbsoluteText y={297} x={28} text={k4.exemptBox2 ? 'X' : ''} fontSize={9}/>
                <AbsoluteText y={316} x={28} text={k4.exemptBox3 ? 'X' : ''} fontSize={9}/>
                <AbsoluteText y={334} x={28} text={k4.exemptBox4 ? 'X' : ''} fontSize={9}/>

                <AbsoluteText y={291} x={468} text={k4.residentOfState}/>
                <AbsoluteText y={348} x={397} text={k4.additionalWitholding}/>

                <Image src={signature.image} style={{position: 'absolute', top: 410, left: 85, width: 120, height: 20}}/>
                <AbsoluteText y={419} x={415} text={moment().format('MM/DD/YYYY')}/>
            </Page>
            <Page size='LETTER' style={{fontSize: 10}}>
                <Image src={K4Page2}/>
                <AbsoluteText y={130} x={498} text={k4.spouseMilitary === 0 ? 'X' : ''} fontSize={9}/>
                <AbsoluteText y={130} x={534} text={k4.spouseMilitary === 1 ? 'X' : ''} fontSize={9}/>

                <AbsoluteText y={140} x={498} text={k4.notMilitary === 0 ? 'X' : ''} fontSize={9}/>
                <AbsoluteText y={140} x={534} text={k4.notMilitary === 1 ? 'X' : ''} fontSize={9}/>

                <AbsoluteText y={160} x={498} text={k4.spouseMilitaryInKentucky === 0 ? 'X' : ''} fontSize={9}/>
                <AbsoluteText y={160} x={534} text={k4.spouseMilitaryInKentucky === 1 ? 'X' : ''} fontSize={9}/>

                <AbsoluteText y={170} x={498} text={k4.liveSameAddress === 0 ? 'X' : ''} fontSize={9}/>
                <AbsoluteText y={170} x={534} text={k4.liveSameAddress === 1 ? 'X' : ''} fontSize={9}/>
                
                <AbsoluteText y={191} x={498} text={k4.spouseDifferentDomicileState === 0 ? 'X' : ''} fontSize={9}/>
                <AbsoluteText y={191} x={534} text={k4.spouseDifferentDomicileState === 1 ? 'X' : ''} fontSize={9}/>

                <AbsoluteText y={202} x={382} text={k4.differentDomicileStateCode} fontSize={9}/>

                <AbsoluteText y={213} x={498} text={k4.inKentuckyWithSpouse === 0 ? 'X' : ''} fontSize={9}/>
                <AbsoluteText y={213} x={534} text={k4.inKentuckyWithSpouse === 1 ? 'X' : ''} fontSize={9}/>

                <AbsoluteText y={449} x={56} text={k4.resideInDifferentState === 0 ? 'X' : ''} fontSize={9}/>
                <AbsoluteText y={449} x={128} text={k4.resideInDifferentState === 1 ? 'X' : ''} fontSize={9}/>
                <AbsoluteText y={449} x={200} text={k4.resideInDifferentState === 2 ? 'X' : ''} fontSize={9}/>
                <AbsoluteText y={449} x={272} text={k4.resideInDifferentState === 3 ? 'X' : ''} fontSize={9}/>
                <AbsoluteText y={449} x={345} text={k4.resideInDifferentState === 4 ? 'X' : ''} fontSize={9}/>
                <AbsoluteText y={459} x={56} text={k4.resideInDifferentState === 5 ? 'X' : ''} fontSize={9}/>
                <AbsoluteText y={470} x={56} text={k4.resideInDifferentState === 6 ? 'X' : ''} fontSize={9}/>

            </Page>
        </Document>
    )
}