/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import Decoder from "../../../../../decoding";
import Address from "../../../../../models/Address";
import StateObject from "../../../../../models/State/StateObject";
import { validateDecimal, validateInteger } from "../../../../../tools";

export default class VA4 extends StateObject {
    claimYourself;
    marriedSpouseNotClaimed;
    numberOfDependents;
    olderThan65;
    spouseOlderThan65;
    legallyBlind;
    spouseLegallyBlind;
    ssn;
    name;
    streetAddress;
    city;
    state;
    zipCode;
    additionalWitholdings;
    notSubjectToWitholdingFromInstructions;
    notSubjectToWitholdingServiceMember;
    signed;

    constructor(
        claimYourself,
        marriedSpouseNotClaimed,
        numberOfDependents,
        olderThan65,
        spouseOlderThan65,
        legallyBlind,
        spouseLegallyBlind,
        ssn,
        name,
        streetAddress,
        city,
        state,
        zipCode,
        additionalWitholdings,
        notSubjectToWitholdingFromInstructions,
        notSubjectToWitholdingServiceMember,
        signed,
    ){
        super();
        this.claimYourself = claimYourself;
        this.marriedSpouseNotClaimed = marriedSpouseNotClaimed;
        this.numberOfDependents = numberOfDependents;
        this.olderThan65 = olderThan65;
        this.spouseOlderThan65 = spouseOlderThan65;
        this.legallyBlind = legallyBlind;
        this.spouseLegallyBlind = spouseLegallyBlind;
        this.ssn = ssn;
        this.name = name;
        this.streetAddress = streetAddress;
        this.city = city;
        this.state = state;
        this.zipCode = zipCode;
        this.additionalWitholdings = additionalWitholdings;
        this.notSubjectToWitholdingFromInstructions = notSubjectToWitholdingFromInstructions;
        this.notSubjectToWitholdingServiceMember = notSubjectToWitholdingServiceMember;
        this.signed = signed;
    }

    static initDefault(){
        return new VA4(
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            false,
            false,
            false,
            ''
        )
    }

    static initFromData(document, userData){
        const firstName = userData.firstName ?? '';
        const middleName = userData.middleName ? `${userData.middleName} ` : '';
        const lastName = userData.lastName ?? '';
        const userAddress = Address.decodeNonStrict(userData.address);

        return new VA4(
            document.claimYourself ?? '',
            document.marriedSpouseNotClaimed ?? '',
            document.numberOfDependents ?? '',
            document.olderThan65 ?? '',
            document.spouseOlderThan65 ?? '',
            document.legallyBlind ?? '',
            document.spouseLegallyBlind ?? '',
            document.ssn ?? '',
            document.name ?? `${firstName} ${middleName}${lastName}`,
            document.streetAddress ?? `${userAddress.thoroughfare} ${userAddress.premise}`,
            document.city ?? userAddress.locality,
            document.state ?? userAddress.administrativeArea,
            document.zipCode ?? userAddress.postalCode,
            document.additionalWitholdings ?? '',
            document.notSubjectToWitholdingFromInstructions ?? false,
            document.notSubjectToWitholdingServiceMember ?? false,
            false
        )
    }

    subtotalPersonalExemptions(){
        return validateInteger(this.claimYourself) + validateInteger(this.marriedSpouseNotClaimed) + validateInteger(this.numberOfDependents);
    }

    subtotalAgeBlind(){
        return validateInteger(this.olderThan65) + validateInteger(this.spouseOlderThan65) 
        + validateInteger(this.legallyBlind) + validateInteger(this.spouseLegallyBlind)
    }

    totalExemptions(){
        return this.subtotalPersonalExemptions() + this.subtotalAgeBlind()
    }

    encode(document, base64){
        return {
            ...document,
            base64: base64,
            claimYourself: this.claimYourself ? validateInteger(this.claimYourself) : null,
            marriedSpouseNotClaimed: this.marriedSpouseNotClaimed ? validateInteger(this.marriedSpouseNotClaimed) : null,
            numberOfDependents: this.numberOfDependents ? validateInteger(this.numberOfDependents) : null,
            olderThan65: this.olderThan65 ? validateInteger(this.olderThan65) : null,
            spouseOlderThan65: this.spouseOlderThan65 ? validateInteger(this.spouseOlderThan65) : null,
            legallyBlind: this.legallyBlind ? validateInteger(this.legallyBlind) : null,
            spouseLegallyBlind: this.spouseLegallyBlind ? validateInteger(this.spouseLegallyBlind) : null,
            ssn: this.ssn,
            name: this.name,
            streetAddress: this.streetAddress,
            city: this.city,
            state: this.state,
            zipCode: validateInteger(this.zipCode),
            additionalWitholdings: this.additionalWitholdings ? validateDecimal(this.additionalWitholdings) : null,
            notSubjectToWitholdingFromInstructions: this.notSubjectToWitholdingFromInstructions,
            notSubjectToWitholdingServiceMember: this.notSubjectToWitholdingServiceMember
        }
    }
}



