/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useEffect, useState } from "react";
import { Button, Card, ListGroup, Modal } from "react-bootstrap";
import W4Form from "./Documents/W4/W4Form";
import SignatureForm from "./Signature/SignatureForm";
import { useParams } from "react-router-dom";
import {
  downloadEdCustomOnboardingDocument,
  downloadMtbCustomOnboardingDocument,
  getEmployeeOnboardingData,
  getRejectedDocumentRequestDocument,
} from "../../services/EmployeeOnboardingService";
import LoadingWrapper from "../../components/LoadingWrapper";
import IT4Form from "./Documents/Tax/IT4/IT4Form";
import VA4Form from "./Documents/Tax/VA4/VA4Form";
import WH4Form from "./Documents/Tax/WH4/WH4Form";
import L4Form from "./Documents/Tax/L4/L4Form";
import StateObject from "../../models/State/StateObject";
import OfferLetterForm from "./Documents/OfferLetter/OfferLetterForm";
import A4Form from "./Documents/Tax/A4/A4Form";
import { pdfjs } from "react-pdf";
import MIW4Form from "./Documents/Tax/MIW4/MIW4Form";
import IT2104Form from "./Documents/Tax/IT2104/IT2104Form";
import K4Form from "./Documents/Tax/K4/K4Form";
import REV419Form from "./Documents/Tax/REV419/REV419Form";
import DirectDepositForm from "./Documents/DirectDeposit/DirectDepositForm";
import F8850Form from "./Documents/F8850/F8850Form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faChevronRight,
  faDownload,
  faExclamationTriangle,
  faHistory,
  faMagnifyingGlass,
  faPen,
  faXmarkCircle,
} from "@fortawesome/free-solid-svg-icons";
import CompanyDocumentListItem from "./CompanyDocumentListItem";
import CompanyDocumentModal from "./CompanyDocumentModal";
import Address from "../../models/Address";
import moment from "moment";
import { downloadBase64 } from "../../tools";
import CustomButton from "../../components/CustomButton";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";
import I9Form from "./Documents/I9/I9Form";
import MDDForm from "./Documents/MDD/MDDForm";
import { OnboardingDocumentsReadOnly } from "../../enums";
import CustomDocumentForm from "./Documents/CustomDocument/CustomDocumentForm";
import { AppGlobals } from "../../App";
import QuickTable from "../../components/QuickTable";
import TextAreaControl from "../../components/TextAreaControl";
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

export default function EmployeeOnboarding({}) {
  const { onboardingToken } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(undefined);
  const [companyDocuments, setCompanyDocuments] = useState([]);
  const [selectedCompanyDocument, setSelectedCompanyDocument] =
    useState(undefined);
  const [selectedCustomDocument, setSelectedCustomDocument] =
    useState(undefined);
  const [documentRequest, setDocumentRequest] = useState({ documents: [] });
  const [modalSwitch, setModalSwitch] = useState("");
  const [userData, setUserData] = useState({});
  const [companyData, setCompanyData] = useState({});
  const [isEmployer, setIsEmployer] = useState(false);
  const [signature, setSignature] = useState(undefined);
  const [selectedRejectedDocuments, setSelectedRejectedDocuments] = useState(
    []
  );

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    setIsLoading(true);
    const response = await getEmployeeOnboardingData(onboardingToken);
    if (response.status === 200) {
      setDocumentRequest(response.documentRequest);
      setCompanyDocuments(response.companyDocuments ?? []);
      setIsEmployer(response.isEmployer);
      setCompanyData(response.companyData);
      const newUserData = structuredClone(response.userData);
      newUserData["address"] = new Address(
        newUserData.address.uid,
        newUserData.address.thoroughfare,
        newUserData.address.premise,
        newUserData.address.locality,
        newUserData.address.administrativeArea,
        newUserData.address.postalCode,
        newUserData.address.lat,
        newUserData.address.lng
      );
      newUserData["ssn"] = newUserData["ssn"]?.toString() ?? "";
      setUserData(new StateObject(newUserData));
      if (
        Object.keys(response.documentRequest.documents).length > 0 ||
        (response.documentRequest.includeEContacts &&
          !response.documentRequest.submittedEContacts)
      ) {
        setModalSwitch("signature");
      }
    } else {
      setError(response.message);
    }
    setIsLoading(false);
  };

  const handleAcknowledgeCompanyDocument = () => {
    let newArray;
    if (documentRequest.tcCompanyUserIdentifier) {
      newArray = Array.from(
        companyDocuments.filter((d) => d.uid != selectedCompanyDocument.uid)
      );
    } else if (documentRequest.lhCompanyUserIdentifier) {
      newArray = Array.from(
        companyDocuments.filter(
          (d) => d.fileIdentifier != selectedCompanyDocument.fileIdentifier
        )
      );
    }
    setCompanyDocuments(newArray);
    setModalSwitch("");
  };

  const hideModal = () => {
    setModalSwitch("");
  };

  function updateDocumentToCompleted(documentType, base64, uid) {
    const newDocumentRequest = structuredClone(documentRequest);
    const completedDocument =
      documentType === "custom"
        ? newDocumentRequest.documents["custom"].find(
            (doc) => doc.documentType === documentType && doc.uid === uid
          )
        : newDocumentRequest.documents[documentType];
    if (isEmployer) {
      completedDocument["employerSubmission"] = base64;
      completedDocument["employerCompleted"] = moment().format("YYYY-MM-DD");
    } else {
      completedDocument["employeeSubmission"] = base64;
      completedDocument["employeeCompleted"] = moment().format("YYYY-MM-DD");
    }
    setDocumentRequest(newDocumentRequest);
  }

  const getDocumentForm = (doc) => {
    switch (doc.documentType) {
      case "w4":
        return (
          <W4Form
            document={doc}
            userData={userData}
            companyData={companyData}
            startDate={documentRequest.startDate}
            signature={signature}
            onboardingToken={onboardingToken}
            updateDocumentToCompleted={updateDocumentToCompleted}
            hideModal={hideModal}
          />
        );
      case "i9":
        return (
          <I9Form
            document={doc}
            userData={userData}
            companyData={companyData}
            ssn={documentRequest?.ssnBase64}
            dl={documentRequest?.dlBase64}
            signature={signature}
            onboardingToken={onboardingToken}
            isEmployer={isEmployer}
            startDate={documentRequest.startDate}
            updateDocumentToCompleted={updateDocumentToCompleted}
            hideModal={hideModal}
          />
        );
      case "dd":
        return (
          <DirectDepositForm
            document={doc}
            userData={userData}
            companyData={companyData}
            signature={signature}
            onboardingToken={onboardingToken}
            updateDocumentToCompleted={updateDocumentToCompleted}
            hideModal={hideModal}
          />
        );
      case "f8850":
        return (
          <F8850Form
            document={doc}
            userData={userData}
            companyData={companyData}
            signature={signature}
            isEmployer={isEmployer}
            onboardingToken={onboardingToken}
            updateDocumentToCompleted={updateDocumentToCompleted}
            hideModal={hideModal}
          />
        );
      case "a4":
        return (
          <A4Form
            document={doc}
            userData={userData}
            companyData={companyData}
            signature={signature}
            isEmployer={isEmployer}
            onboardingToken={onboardingToken}
            hideModal={hideModal}
            updateDocumentToCompleted={updateDocumentToCompleted}
          />
        );
      case "wh4":
        return (
          <WH4Form
            document={doc}
            userData={userData}
            signature={signature}
            onboardingToken={onboardingToken}
            updateDocumentToCompleted={updateDocumentToCompleted}
            hideModal={hideModal}
          />
        );
      case "k4":
        return (
          <K4Form
            document={doc}
            userData={userData}
            signature={signature}
            onboardingToken={onboardingToken}
            updateDocumentToCompleted={updateDocumentToCompleted}
            hideModal={hideModal}
          />
        );
      case "l4":
        return (
          <L4Form
            document={doc}
            userData={userData}
            companyData={companyData}
            signature={signature}
            isEmployer={isEmployer}
            onboardingToken={onboardingToken}
            updateDocumentToCompleted={updateDocumentToCompleted}
            hideModal={hideModal}
          />
        );
      case "mdd":
        return (
          <MDDForm
            document={doc}
            userData={userData}
            companyData={companyData}
            signature={signature}
            onboardingToken={onboardingToken}
            updateDocumentToCompleted={updateDocumentToCompleted}
            hideModal={hideModal}
          />
        );
      case "miw4":
        return (
          <MIW4Form
            document={doc}
            userData={userData}
            companyData={companyData}
            signature={signature}
            isEmployer={isEmployer}
            onboardingToken={onboardingToken}
            updateDocumentToCompleted={updateDocumentToCompleted}
            hideModal={hideModal}
          />
        );
      case "it2104":
        return (
          <IT2104Form
            document={doc}
            userData={userData}
            companyData={companyData}
            signature={signature}
            isEmployer={isEmployer}
            onboardingToken={onboardingToken}
            updateDocumentToCompleted={updateDocumentToCompleted}
            hideModal={hideModal}
          />
        );
      case "it4":
        return (
          <IT4Form
            document={doc}
            userData={userData}
            signature={signature}
            onboardingToken={onboardingToken}
            updateDocumentToCompleted={updateDocumentToCompleted}
            hideModal={hideModal}
          />
        );
      case "rev419":
        return (
          <REV419Form
            document={doc}
            userData={userData}
            companyData={companyData}
            signature={signature}
            isEmployer={isEmployer}
            onboardingToken={onboardingToken}
            updateDocumentToCompleted={updateDocumentToCompleted}
            hideModal={hideModal}
          />
        );
      case "va4":
        return (
          <VA4Form
            document={doc}
            userData={userData}
            signature={signature}
            onboardingToken={onboardingToken}
            updateDocumentToCompleted={updateDocumentToCompleted}
            hideModal={hideModal}
          />
        );
      case "offerLetter":
        return (
          <OfferLetterForm
            document={doc}
            userData={userData}
            companyData={companyData}
            signature={signature}
            onboardingToken={onboardingToken}
            updateDocumentToCompleted={updateDocumentToCompleted}
            hideModal={hideModal}
          />
        );
    }
  };

  const documentsToComplete = Object.entries(documentRequest.documents).filter(
    ([_, doc]) => {
      if (Array.isArray(doc)) {
        return doc.some(
          (subDoc) =>
            (isEmployer &&
              "employerCompleted" in subDoc &&
              !subDoc.employerCompleted &&
              subDoc.employeeCompleted) ||
            (!isEmployer && !subDoc.employeeCompleted)
        );
      } else {
        return (
          (isEmployer &&
            "employerCompleted" in doc &&
            !doc.employerCompleted &&
            doc.employeeCompleted) ||
          (!isEmployer && !doc.employeeCompleted)
        );
      }
    }
  );
  
  const completedDocuments = Object.entries(documentRequest.documents).filter(
    ([_, doc]) => {
      if (Array.isArray(doc)) {
        return doc.some(
          (subDoc) =>
            (isEmployer && subDoc.employerCompleted) ||
            (!("employerCompleted" in subDoc) && subDoc.employeeCompleted) ||
            (!isEmployer && subDoc.employeeCompleted)
        );
      } else {
        return (
          (isEmployer && doc.employerCompleted) ||
          (!("employerCompleted" in doc) && doc.employeeCompleted) ||
          (!isEmployer && doc.employeeCompleted)
        );
      }
    }
  );
  
  const awaitingEmployeeDocuments = Object.entries(
    documentRequest.documents
  ).filter(([_, doc]) => {
    if (Array.isArray(doc)) {
      return doc.some((subDoc) => !subDoc.employeeCompleted && isEmployer);
    } else {
      return !doc.employeeCompleted && isEmployer;
    }
  });

  const listItems = documentsToComplete.flatMap(([_, doc]) => {
    if (Array.isArray(doc)) {
      return doc.map((customDoc) => (
        <ListGroup.Item
          key={`${customDoc.documentType}-${customDoc.uid}`}
          style={{
            display: "flex",
            color: "var(--bs-primary)",
            justifyContent: "space-between",
            alignItems: "center",
          }}
          action
          onClick={() => {
            setSelectedCustomDocument(customDoc);
            setModalSwitch("customDocument");
          }}
        >
          <span>{customDoc?.documentInfo?.name}</span>
          <FontAwesomeIcon icon={faChevronRight} />
        </ListGroup.Item>
      ));
    }
  
    return (
      <ListGroup.Item
        key={doc.documentType}
        style={{
          display: "flex",
          color: "var(--bs-primary)",
          justifyContent: "space-between",
          alignItems: "center",
          gap: 8,
        }}
      >
        <span>{OnboardingDocumentsReadOnly[doc.documentType]}</span>
        <div style={{ display: "flex", alignItems: "center", gap: 8 }}>
          <CustomButton
            onClick={() => {
              setModalSwitch("rejectedVersions");
              setSelectedRejectedDocuments(
                documentRequest.rejectedDocuments[doc.documentType]
              );
            }}
            disabled={
              !documentRequest.rejectedDocuments?.[doc.documentType]?.length
            }
            icon={faHistory}
          />
          <CustomButton
            onClick={() => {
              setModalSwitch(doc.documentType);
            }}
            icon={faPen}
          />
        </div>
      </ListGroup.Item>
    );
  });

  const completedListItems = completedDocuments.flatMap(([_, doc]) => {
    if (Array.isArray(doc)) {
      return doc.map((customDoc) => {
        return (
          <ListGroup.Item
            key={customDoc.uid}
            style={{
              display: "flex",
              color: "var(--bs-primary)",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div style={{ marginRight: 8, opacity: 0.5 }}>
              <span>{customDoc.documentInfo.name}</span>
              <FontAwesomeIcon icon={faCheck} style={{ marginLeft: 8 }} />
            </div>
            <div style={{ display: "flex", gap: 12 }}>
              <CustomButton
                icon={faDownload}
                onClick={async () => {
                  const response = await downloadEdCustomOnboardingDocument(
                    customDoc.uid
                  );
                  if (response.status === 200) {
                    downloadBase64(
                      response.base64,
                      `${userData?.firstName} ${userData?.lastName} - ${customDoc.documentInfo.name}`
                    );
                  } else {
                    AppGlobals.alert("danger", response.message);
                  }
                }}
              />
            </div>
          </ListGroup.Item>
        );
      })
    }

    return (
      <ListGroup.Item
        key={doc.documentType}
        style={{
          display: "flex",
          color: "var(--bs-primary)",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div style={{ marginRight: 8, opacity: 0.5 }}>
          <span>{OnboardingDocumentsReadOnly[doc.documentType]}</span>
          <FontAwesomeIcon icon={faCheck} style={{ marginLeft: 8 }} />
        </div>
        <div style={{ display: "flex", gap: 8 }}>
          {isEmployer && doc["employerCompleted"] && !documentRequest.adminDismissed && (
            <CustomButton
              icon={faPenToSquare}
              onClick={() => {
                setModalSwitch(doc.documentType);
              }}
            />
          )} 
          <CustomButton
            onClick={() => {
              setModalSwitch("rejectedVersions");
              setSelectedRejectedDocuments(
                documentRequest.rejectedDocuments[doc.documentType]
              );
            }}
            disabled={
              !documentRequest.rejectedDocuments?.[doc.documentType]?.length
            }
            icon={faHistory}
          />
          <CustomButton
            icon={faDownload}
            onClick={() => {
              downloadBase64(
                doc.employerCompleted
                  ? doc.employerSubmission
                  : doc.employeeSubmission,
                `${userData?.firstName} ${userData?.lastName} ${
                  OnboardingDocumentsReadOnly[doc.documentType]
                }`
              );
            }}
          />
        </div>
      </ListGroup.Item>
    );
  });

  const companyDocumentsItems = companyDocuments.map((doc) => {
    return (
      <CompanyDocumentListItem
        key={doc.fileIdentifier ?? doc.uid}
        document={doc}
        showModal={() => {
          setSelectedCompanyDocument(doc);
          setModalSwitch("companyDocument");
        }}
      />
    );
  });

  const awaitingEmployeeDocumentItems = awaitingEmployeeDocuments.flatMap(
    ([_, doc]) => {

      if (Array.isArray(doc)) {
        return doc.map((customDoc) => {
          return (
            <ListGroup.Item
              key={customDoc.uid}
              style={{
                display: "flex",
                color: "var(--bs-primary)",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <span>{customDoc?.documentInfo?.name}</span>
              <span style={{ color: "var(--bs-danger)" }}>Awaiting Employee</span>
            </ListGroup.Item>
          );
        })
      }

      return (
        <ListGroup.Item
          key={doc.documentType}
          style={{
            display: "flex",
            color: "var(--bs-primary)",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <span>{OnboardingDocumentsReadOnly[doc.documentType]}</span>
          <span style={{ color: "var(--bs-danger)" }}>Awaiting Employee</span>
        </ListGroup.Item>
      );
    }
  );

  const modals = Object.entries(documentRequest.documents)
    .filter(([_, doc]) => Boolean(OnboardingDocumentsReadOnly[doc?.documentType]))
    .map(([_, doc]) => {
      return (
        <Modal
          key={doc.documentType}
          show={modalSwitch === doc.documentType}
          onHide={hideModal}
          fullscreen
        >
          {getDocumentForm(doc)}
        </Modal>
      );
    });

  return (
    <LoadingWrapper isLoading={isLoading}>
      {!error ? (
        <div
          style={{
            padding: 12,
            display: "flex",
            flexDirection: "column",
            overflowY: "auto",
            gap: 12,
            height: "100%",
          }}
        >
          {!signature && documentsToComplete.length > 0 ? (
            <Card style={{ flex: 1 }}>
              <Card.Body
                style={{ display: "flex", flexDirection: "column", gap: 12 }}
              >
                <Button
                  onClick={() => {
                    setModalSwitch("signature");
                  }}
                >
                  Create Signature
                </Button>
              </Card.Body>
            </Card>
          ) : (
            <Card style={{ flex: 1 }}>
              <Card.Header>
                <Card.Title>{`Onboarding Documents for ${userData?.firstName} ${userData?.lastName}`}</Card.Title>
              </Card.Header>
              <Card.Body
                style={{ display: "flex", flexDirection: "column", gap: 12 }}
              >
                {listItems.length > 0 && (
                  <>
                    <span
                      style={{ opacity: 0.5, fontWeight: "bold", fontSize: 14 }}
                    >
                      DOCUMENTS TO SIGN
                    </span>
                    <ListGroup style={{ marginBottom: 12 }}>
                      {listItems}
                    </ListGroup>
                  </>
                )}
                {completedListItems.length > 0 && (
                  <>
                    <span
                      style={{ opacity: 0.5, fontWeight: "bold", fontSize: 14 }}
                    >
                      COMPLETED DOCUMENTS
                    </span>
                    <ListGroup style={{ marginBottom: 12 }}>
                      {completedListItems}
                    </ListGroup>
                  </>
                )}
                {awaitingEmployeeDocumentItems.length > 0 && (
                  <>
                    <span
                      style={{ opacity: 0.5, fontWeight: "bold", fontSize: 14 }}
                    >
                      DOCUMENTS AWAITING EMPLOYEE
                    </span>
                    <ListGroup style={{ marginBottom: 12 }}>
                      {awaitingEmployeeDocumentItems}
                    </ListGroup>
                  </>
                )}
              </Card.Body>
            </Card>
          )}
          {companyDocuments.length > 0 && (
            <Card style={{ flex: 1 }}>
              <Card.Header>
                <Card.Title>Company Documents to Acknowledge</Card.Title>
              </Card.Header>
              <Card.Body>
                <ListGroup>{companyDocumentsItems}</ListGroup>
              </Card.Body>
            </Card>
          )}
          <Modal
            show={modalSwitch === "companyDocument"}
            onHide={hideModal}
            backdrop="static"
            size="xl"
            fullscreen="xl-down"
          >
            <CompanyDocumentModal
              onboardingToken={onboardingToken}
              document={selectedCompanyDocument}
              onAcknowledge={handleAcknowledgeCompanyDocument}
            />
          </Modal>
          <Modal
            show={modalSwitch === "customDocument"}
            onHide={hideModal}
            backdrop="static"
            fullscreen
          >
            <CustomDocumentForm
              onboardingToken={onboardingToken}
              document={selectedCustomDocument}
              signature={signature}
              updateDocumentToCompleted={updateDocumentToCompleted}
              hideModal={hideModal}
            />
          </Modal>
          <Modal
            size="xl"
            show={modalSwitch === "signature"}
            onHide={hideModal}
            backdrop="static"
          >
            <SignatureForm
              onboardingToken={onboardingToken}
              isEmployer={isEmployer}
              hideModal={hideModal}
              signature={signature}
              setSignature={setSignature}
              userData={userData}
              companyData={companyData}
              documentRequest={documentRequest}
            />
          </Modal>
          {modals}
          <Modal
            centered
            show={modalSwitch === "rejectedVersions"}
            onHide={hideModal}
          >
              <RejectedDocumentsModal
                rejectedDocuments={selectedRejectedDocuments}
                isEmployer={isEmployer}
              />
          </Modal>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <Card>
            <Card.Header>
              <Card.Title style={{ textAlign: "center" }}>Oops!</Card.Title>
            </Card.Header>
            <Card.Body
              style={{ display: "flex", alignItems: "center", gap: 12 }}
            >
              <span style={{ margin: "auto", textAlign: "center" }}>
                <FontAwesomeIcon
                  size="1x"
                  icon={faExclamationTriangle}
                  style={{ color: "var(--bs-danger)", marginRight: 8 }}
                />
                {error}
              </span>
            </Card.Body>
          </Card>
        </div>
      )}
    </LoadingWrapper>
  );
}

function RejectedDocumentsModal({ rejectedDocuments, isEmployer}) {
  const documentType = rejectedDocuments[0].documentType;
  const [document, setDocument] = useState();
  const [isPreviewing, setIsPreviewing] = useState();

  async function previewDocument(rejectedDocument) {
    setIsPreviewing(rejectedDocument.uid)

    const response = await getRejectedDocumentRequestDocument(documentType, rejectedDocument.uid);
    if(response.status === 200){
      response.documentRequestDocument.documentRejection =
            rejectedDocument.documentRejection;
          response.documentRequestDocument.documentType = documentType;
          if (isEmployer) {
            if (response.documentRequestDocument.employerSubmission) {
              response.documentRequestDocument.base64 =
                response.documentRequestDocument.employerSubmission;
            } else if (response.documentRequestDocument.employeeSubmission) {
              response.documentRequestDocument.base64 =
                response.documentRequestDocument.employeeSubmission;
            }
          } else {
            if (response.documentRequestDocument.employeeSubmission) {
              response.documentRequestDocument.base64 =
                response.documentRequestDocument.employeeSubmission;
            }
          }
      setDocument(response.documentRequestDocument);
    }else{
      AppGlobals.alert('danger', response.message);
    }
    setIsPreviewing(undefined);
  }

  const rows = rejectedDocuments.map((rejectedDocument) => {
    return {
      columns: [
          { value: moment(rejectedDocument.documentRejection.dateTime).format("lll")},
          { value: `${rejectedDocument.documentRejection.firstName} ${rejectedDocument.documentRejection.lastName}` },
          { content: 
          <CustomButton
            title="Preview"
            isLoading={isPreviewing === rejectedDocument.uid}
            onClick={() => previewDocument(rejectedDocument)}
          >
            <FontAwesomeIcon icon={faMagnifyingGlass} />
          </CustomButton>
        },
      ]
  }
  });

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>
          Rejected Versions for {OnboardingDocumentsReadOnly[documentType]}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <QuickTable
          responsive={false}
          headers={[
            { label: 'Date Rejected', sortable: false },
            { label: 'Rejected By', sortable: false },
            { label: 'View', sortable: false }]}
          rows={rows}
        />
      </Modal.Body>
      <Modal
        fullscreen
        show={Boolean(document)}
        onHide={() => setDocument(undefined)}
      >
        {Boolean(document) && (
          <RejectedDocumentPreview rejectedDocument={document} />
        )}
      </Modal>
    </>
  );
}

function RejectedDocumentPreview({ rejectedDocument }) {
  const [base64, _setBase64] = useState(rejectedDocument.base64);
  const [url, setUrl] = useState("");

  useEffect(() => {
    const fetchAndSetUrl = async () => {
      if (base64) {
        try {
          const fetchResponse = await fetch(base64);
          const blob = await fetchResponse.blob();
          setUrl(window.URL.createObjectURL(blob));
        } catch (error) {
          console.error("Error fetching or creating URL:", error);
        }
      }
    };

    fetchAndSetUrl();
  }, [base64]);

  const renderDocument = () => {
    if (base64.split(",", 1)[0].includes("pdf")) {
      return (
        <div
          style={{
            backgroundColor: "#f4f4f5",
            height: "100%",
            width: "100%",
            overflow: "hidden",
          }}
        >
          <iframe
            src={url}
            width="100%"
            height="100%"
            title="Embedded Page"
            style={{ border: "none", overflow: "hidden" }}
          />
        </div>
      );
    } else if (base64) {
      return (
        <div
          style={{
            display: "flex",
            width: "100%",
            height: "100%",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "4px",
            backgroundColor: "#f4f4f5",
            overflow: "hidden",
          }}
        >
          <img
            src={base64}
            alt={document.name}
            style={{ maxWidth: "100%", maxHeight: "100%" }}
          />
        </div>
      );
    }
    return null;
  };

  const failedToPreviewDocument = () => {
    return (
      <div
        style={{
          display: "flex",
          height: "100%",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "8px",
          padding: "16px",
          color: "var(--bs-danger)",
        }}
      >
        <FontAwesomeIcon
          icon={faXmarkCircle}
          style={{ marginRight: 8, height: 24, width: 24 }}
        />
        <span style={{ fontWeight: 500 }}>Failed to preview document</span>
      </div>
    );
  };

  return (
    <>
      <Modal.Header closeButton>
        <div>
          <Modal.Title>Previewing Rejected Document</Modal.Title>
          <p style={{ margin: 0, fontSize: "0.9rem", opacity: .7 }}>
            {`${
              OnboardingDocumentsReadOnly[rejectedDocument.documentType]
            } - Rejected by ${rejectedDocument.documentRejection.firstName} ${rejectedDocument.documentRejection.lastName} - ${moment(
              rejectedDocument.documentRejection.dateTime
            ).format("lll")}`}
          </p>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div style={{ marginBottom: 8 }}>
          <TextAreaControl
            rows={5}
            label={"Revision Message"}
            value={rejectedDocument.documentRejection.rejectionMessage}
            onChange={() => {}}
            disabled={true}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            overflow: "hidden",
            height: "100%",
          }}
        >
          {renderDocument() ?? failedToPreviewDocument()}
        </div>
      </Modal.Body>
    </>
  );
}
