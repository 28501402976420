/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { Document, Page, Image, } from '@react-pdf/renderer';
import { formatEin, formatSsn, validateDecimal, validateInteger } from '../../../../tools';
import moment from 'moment';
import W4Page1 from '../../../../../src/assets/fw4-1.png';
import W4Page2 from '../../../../../src/assets/fw4-2.png';
import W4Page3 from '../../../../../src/assets/fw4-3.png';
import W4Page4 from '../../../../../src/assets/fw4-4.png';
import AbsoluteText from '../AbsoluteText';

export default function W4Pdf({w4, signature}) {
    
    const step2b2c = w4.step2b2a && w4.step2b2b ? (validateDecimal(w4.step2b2a) + validateDecimal(w4.step2b2b)) : undefined;
    const step2b4 = w4.step2b1 ? validateDecimal(w4.step2b1) / validateInteger(w4.step2b3) : step2b2c ? step2b2c / validateInteger(w4.step2b3) : undefined;
    const step4b3 = w4.step4b1 && w4.step4b2 ? w4.step4b1 > w4.step4b2 ? validateDecimal(w4.step4b1) - validateDecimal(w4.step4b2) : 0 : undefined;
    const step4b5 = step4b3 !== undefined && w4.step4b4 ? step4b3 + validateDecimal(w4.step4b4) : undefined;

    return (
        <Document>
            <Page size='LETTER' style={{fontSize: 10}}>
                <Image src={W4Page1}/>
                <AbsoluteText y={92} x={100} text={w4.firstNameMiddleInitial}/>
                <AbsoluteText y={92} x={280} text={w4.lastName}/>
                <AbsoluteText y={92} x={480} text={formatSsn(w4.ssn ?? '')}/>
                <AbsoluteText y={116} x={100} text={w4.address}/>
                <AbsoluteText y={140} x={100} text={w4.cityStateZip}/>
                <AbsoluteText y={154 + ((w4.filingStatus ?? 0) * 11.5)} x={116} text='X'/>
                <AbsoluteText y={380} x={564.5} text={w4.has2Jobs ? 'X' : ''}/>

                <AbsoluteText y={445} x={411} text={w4.step3Children.toLocaleString()}/>
                <AbsoluteText y={463} x={411} text={w4.step3OtherDependents.toLocaleString()}/>
                <AbsoluteText y={492} x={512} text={w4.step3Total.toLocaleString()}/>
                <AbsoluteText y={528} x={512} text={w4.step4OtherIncome.toLocaleString()}/>
                <AbsoluteText y={569} x={512} text={w4.step4Deductions.toLocaleString()}/>
                <AbsoluteText y={593} x={512} text={w4.step4ExtraWitholding?.toLocaleString()}/>

                <Image src={signature.image} style={{position: 'absolute', top: 648, left: 108, width: 74, height: 25}}/>
                <AbsoluteText y={657} x={464} text={moment().format('MM/DD/YYYY')}/>
                
                <AbsoluteText y={705} x={105} text={w4.employerName}/>
                <AbsoluteText y={720} x={105} text={w4.employerAddress}/>
                <AbsoluteText y={720} x={400} text={w4.firstDateOfEmployment ? moment(w4.firstDateOfEmployment).format('MM/DD/YYYY') : ''}/>
                <AbsoluteText y={720} x={480} text={formatEin(w4.ein)}/>
                
            </Page>
            <Page size='LETTER' style={{fontSize: 10}}>
                <Image src={W4Page2}/>
            </Page>
            <Page size='LETTER' style={{fontSize: 10}}>
                <Image src={W4Page3}/>
                <AbsoluteText y={172} x={511} text={w4.step2b1?.toLocaleString() ?? ''}/>
                <AbsoluteText y={249} x={511} text={w4.step2b2a?.toLocaleString() ?? ''}/>
                <AbsoluteText y={302} x={511} text={w4.step2b2b?.toLocaleString() ?? ''}/>
                <AbsoluteText y={320} x={511} text={step2b2c?.toLocaleString() ?? ''}/>
                <AbsoluteText y={350} x={506} text={w4.step2b3}/>
                <AbsoluteText y={391} x={511} text={step2b4?.toLocaleString() ?? ''}/>
                <AbsoluteText y={462} x={511} text={w4.step4b1?.toLocaleString() ?? ''}/>
                <AbsoluteText y={492} x={511} text={w4.step4b2?.toLocaleString() ?? ''}/>
                <AbsoluteText y={533} x={511} text={step4b3?.toLocaleString() ?? ''}/>
                <AbsoluteText y={563} x={511} text={w4.step4b4?.toLocaleString() ?? ''}/>
                <AbsoluteText y={581} x={511} text={step4b5?.toLocaleString() ?? ''}/>
            </Page>
            <Page size='LETTER' style={{fontSize: 10}}>
                <Image src={W4Page4}/>
            </Page>
        </Document>
    )
}