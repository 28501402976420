/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import Decoder from "../../../../../decoding";
import Address from "../../../../../models/Address";
import StateObject from "../../../../../models/State/StateObject";
import { addressToString, base64PdfToBase64Images, validateDecimal, validateInteger } from "../../../../../tools";

export default class IT2104 extends StateObject {
    employeeSubmission;
    firstNameAndMiddleInitial;
    lastName;
    ssn;
    homeAddress;
    apartmentNumber;
    city;
    state;
    zipCode;
    singleOrMarried
    residentOfNewYork;
    residentOfYonkers;
    totalNumberAllowancesState;
    totalNumberAllowancesCity;
    stateAdditional;
    cityAdditional;
    yonkersAdditional;
    signed;
    //Employer
    employeeMoreThan14Exemptions;
    employeeNewOrRehire;
    firstDateWorked;
    healthBenefitsAvailable;
    dateQualified;
    companyNameAndAddress;
    ein;

    constructor(
        employeeSubmission,
        firstNameAndMiddleInitial,
        lastName,
        ssn,
        homeAddress,
        apartmentNumber,
        city,
        state,
        zipCode,
        singleOrMarried,
        residentOfNewYork,
        residentOfYonkers,
        totalNumberAllowancesState,
        totalNumberAllowancesCity,
        stateAdditional,
        cityAdditional,
        yonkersAdditional,
        signed,
        employeeMoreThan14Exemptions,
        employeeNewOrRehire,
        firstDateWorked,
        healthBenefitsAvailable,
        dateQualified,
        companyNameAndAddress,
        ein,
    ){
        super();
        this.employeeSubmission = employeeSubmission;
        this.firstNameAndMiddleInitial = firstNameAndMiddleInitial;
        this.lastName = lastName;
        this.ssn = ssn;
        this.homeAddress = homeAddress;
        this.apartmentNumber = apartmentNumber;
        this.city = city;
        this.state = state;
        this.zipCode = zipCode;
        this.singleOrMarried = singleOrMarried;
        this.residentOfNewYork = residentOfNewYork;
        this.residentOfYonkers = residentOfYonkers;
        this.totalNumberAllowancesState = totalNumberAllowancesState;
        this.totalNumberAllowancesCity = totalNumberAllowancesCity;
        this.stateAdditional = stateAdditional;
        this.cityAdditional = cityAdditional;
        this.yonkersAdditional = yonkersAdditional;
        this.signed = signed;
        this.employeeMoreThan14Exemptions = employeeMoreThan14Exemptions;
        this.employeeNewOrRehire = employeeNewOrRehire;
        this.firstDateWorked = firstDateWorked;
        this.healthBenefitsAvailable = healthBenefitsAvailable;
        this.dateQualified = dateQualified;
        this.companyNameAndAddress = companyNameAndAddress;
        this.ein = ein;
    }

    static initDefault(){
        return new IT2104(
            [],
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            undefined,
            undefined,
            undefined,
            '',
            '',
            '',
            '',
            '',
            false,
            '',
            '',
            '',
            '',
            '',
            '',
            '',
        )
    }

    static async initFromData(document, userData, companyData, isEmployer){
        const firstName = userData.firstName ?? '';
        const middleInitial = userData.middleName?.[0] ?? '';
        const lastName = userData.lastName ?? '';
        const userAddress = Address.decodeNonStrict(userData.address);
        const companyAddress = Address.decodeNonStrict(companyData.address);
        const companyName = companyData.companyName ?? companyData.name ?? '';
        const companyNameAndAddress = `${companyName} ${addressToString(companyAddress)}`;
        const ein = companyData.ein ?? '';

        let employeeSubmission = [];
        if (isEmployer) {
            employeeSubmission = await base64PdfToBase64Images(document.employeeSubmission, 1173);
        }

        return new IT2104(
            employeeSubmission,
            document.firstNameAndMiddleInitial ?? `${firstName} ${middleInitial}`,
            document.lastName ?? lastName,
            document.ssn ?? '',
            document.homeAddress ?? userAddress.thoroughfare,
            document.apartmentNumber ?? userAddress.premise,
            document.city ?? userAddress.locality,
            document.state ?? userAddress.administrativeArea,
            document.zipCode ?? userAddress.postalCode,
            document.singleOrMarried !== null ? document.singleOrMarried : undefined,
            document.residentOfNewYork !== null ? document.residentOfNewYork : undefined,
            document.residentOfYonkers !== null ? document.residentOfYonkers : undefined,
            document.totalNumberAllowancesCity ?? '',
            document.totalNumberAllowancesState ?? '',
            document.stateAdditional ?? '',
            document.cityAdditional ?? '',
            document.yonkersAdditional ?? '',
            false,
            document.employeeMoreThan14Exemptions ?? '',
            document.employeeNewOrRehire ?? '',
            document.firstDateWorked ?? '',
            document.healthBenefitsAvailable ?? '',
            document.dateQualified ?? '',
            document.companyNameAndAddress ?? companyNameAndAddress,
            document.ein ?? ein
        )
    }

    encode(document, base64){
        return {
            ...document,
            base64: base64,
            firstNameAndMiddleInitial: this.firstNameAndMiddleInitial,
            lastName: this.lastName,
            ssn: this.ssn,
            homeAddress: this.homeAddress,
            apartmentNumber: this.apartmentNumber ? this.apartmentNumber : this.apartmentNumber,
            city: this.city,
            state: this.state,
            zipCode: this.zipCode,
            singleOrMarried: this.singleOrMarried,
            residentOfNewYork: this.residentOfNewYork,
            residentOfYonkers: this.residentOfYonkers,
            totalNumberAllowancesState: this.totalNumberAllowancesState ? validateInteger(this.totalNumberAllowancesState) : null,
            totalNumberAllowancesCity: this.totalNumberAllowancesCity ? validateInteger(this.totalNumberAllowancesCity) : null,
            stateAdditional: this.stateAdditional ? validateDecimal(this.stateAdditional) : null,
            cityAdditional: this.cityAdditional ? validateDecimal(this.cityAdditional) : null,
            yonkersAdditional: this.yonkersAdditional ? validateDecimal(this.yonkersAdditional) : null,
            employeeMoreThan14Exemptions: this.employeeMoreThan14Exemptions,
            employeeNewOrRehire: this.employeeNewOrRehire,
            firstDateWorked: this.firstDateWorked ? this.firstDateWorked : null,
            healthBenefitsAvailable: this.healthBenefitsAvailable,
            dateQualified: this.dateQualified ? this.dateQualified : null,
            companyNameAndAddress: this.companyNameAndAddress,
            ein: this.ein
        }
    }

}