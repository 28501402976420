/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useEffect, useRef, useState } from 'react';
import WH4Page1 from '../../../../../assets/WH-4-1.png';
import WH4Page2 from '../../../../../assets/WH-4-2.png';
import { Button, Modal } from 'react-bootstrap';
import ControlStateProps from '../../../../../models/State/ControlStateProps';
import { pdf } from '@react-pdf/renderer';
import DocumentWizard from '../../../DocumentWizard';
import DocumentField from '../../../DocumentField';
import SubscriberWrapper from '../../../../../components/SubscriberWrapper';
import DocumentCheckbox from '../../../DocumentCheckbox';
import DocumentSignatureButton from '../../../DocumentSignatureButton';
import { Validation } from '../../../../../validation';
import moment from 'moment';
import WH4Pdf from './WH4Pdf';
import { reactPdfToBase64 } from '../../../../../tools';
import { submitDocument } from '../../../../../services/EmployeeOnboardingService';
import { AppGlobals } from '../../../../../App';
import WH4 from './WH4';

export default function WH4Form({document, userData, signature, hideModal, onboardingToken, updateDocumentToCompleted}) {
    const [zoom, setZoom] = useState(1);
    const [wh4, _] = useState(WH4.initFromData(document, userData))

    const fieldRefs = [
        useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef(),
        useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef(),
    ]

    const handleSubmit = async () => {
        const pdf = <WH4Pdf signature={signature} wh4={wh4}/>;
        const base64 = await reactPdfToBase64(pdf);
        if(base64){
            const response = await submitDocument(wh4.encode(document, base64), onboardingToken);
            if (response.status === 200) {
                updateDocumentToCompleted('wh4', base64);
                hideModal();
                AppGlobals.alert('success', response.message);
            } else {
                AppGlobals.alert('danger', response.message);
            }
        }
    }

    return (
        <>
            <Modal.Header closeButton>
                
            </Modal.Header>
            <Modal.Body style={{padding: 0, overflowX: 'scroll', backgroundColor: 'lightgray'}}>
                <div style={{transform: `scale(${zoom})`, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: 12, height: ((850 * 2) + (12) + 16) * zoom}}>
                    <div style={{position: 'relative', marginLeft: 'auto', marginRight: 'auto'}}>
                        <img src={WH4Page1} style={{width: 1100, height: 850}}/>
                        <DocumentField innerRef={fieldRefs[0]} stateProps={new ControlStateProps('fullName', wh4, Validation.nonEmptyString)} maxLength={50} x={210} y={225} width={360} fontSize={14}/>
                        <DocumentField innerRef={fieldRefs[1]} stateProps={new ControlStateProps('ssnOrITIN', wh4, Validation.nonEmptyString)} type='number' maxLength={9} x={781} y={225} width={150} fontSize={14}/>
                        <DocumentField innerRef={fieldRefs[2]} stateProps={new ControlStateProps('homeAddress', wh4, Validation.nonEmptyString)} maxLength={30} x={222} y={250} width={225} fontSize={13}/>
                        <DocumentField innerRef={fieldRefs[3]} stateProps={new ControlStateProps('city', wh4, Validation.nonEmptyString)} maxLength={20} x={490} y={250} width={160} fontSize={14}/>
                        <DocumentField innerRef={fieldRefs[4]} stateProps={new ControlStateProps('state', wh4, Validation.nonEmptyString)} maxLength={2} x={698} y={250} width={40} fontSize={14}/>
                        <DocumentField innerRef={fieldRefs[5]} stateProps={new ControlStateProps('zipCode', wh4, Validation.nonEmptyString)} maxLength={5} type='number' x={810} y={250} width={100} fontSize={14}/>

                        <DocumentField innerRef={fieldRefs[6]} stateProps={new ControlStateProps('countyOfResidence', wh4, Validation.nonEmptyString)} maxLength={20} x={447} y={275} width={200} fontSize={14}/>
                        <DocumentField innerRef={fieldRefs[7]} stateProps={new ControlStateProps('countyOfEmployment', wh4, Validation.nonEmptyString)} maxLength={20} x={510} y={300} width={200} fontSize={14}/>=
                        <DocumentCheckbox innerRef={fieldRefs[8]} stateProps={new ControlStateProps('countiesEffectiveNextYear', wh4, Validation.nonEmptyString)} x={756} y={325} height={14} width={24}/>

                        <DocumentField innerRef={fieldRefs[9]} stateProps={new ControlStateProps('entitledExemption', wh4)} type='number' minInt={0} maxInt={1} x={905} y={367} width={30} fontSize={14}/>
                        <DocumentField innerRef={fieldRefs[10]} stateProps={new ControlStateProps('marriedExemption', wh4)} type='number' minInt={0} maxInt={1} x={905} y={402} width={30} fontSize={14}/>
                        <DocumentField innerRef={fieldRefs[11]} stateProps={new ControlStateProps('dependentExemptions', wh4)} type='number' minInt={0} maxInt={255} x={905} y={425} width={30} fontSize={14}/>

                        <DocumentCheckbox innerRef={fieldRefs[12]} stateProps={new ControlStateProps('olderThan65', wh4)} x={497} y={482} height={14} width={24}/>
                        <DocumentCheckbox innerRef={fieldRefs[13]} stateProps={new ControlStateProps('legallyBlind', wh4)} x={560} y={482} height={14} width={24}/>
                        <DocumentCheckbox innerRef={fieldRefs[14]} stateProps={new ControlStateProps('spouseOlderThan65', wh4)} x={700} y={482} height={14} width={24}/>
                        <DocumentCheckbox innerRef={fieldRefs[15]} stateProps={new ControlStateProps('spouseLegallyBlind', wh4)} x={765} y={482} height={14} width={24}/>

                        <DocumentField innerRef={fieldRefs[16]} stateProps={new ControlStateProps('entitledExemptionForDependents', wh4)} type='number' minInt={0} maxInt={255} x={905} y={542} width={30} fontSize={14}/>
                        <DocumentField innerRef={fieldRefs[17]} stateProps={new ControlStateProps('entitledExemptionForDependentsFirstTime', wh4)} type='number' minInt={0} maxInt={255} x={905} y={564} width={30} fontSize={14}/>
                        <DocumentField innerRef={fieldRefs[18]} stateProps={new ControlStateProps('entitledExemptionForAdoptedDependents', wh4)} type='number' minInt={0} maxInt={255} x={905} y={586} width={30} fontSize={14}/>
                        <DocumentField innerRef={fieldRefs[19]} stateProps={new ControlStateProps('additionalStateWitholding', wh4)} type='number' minDecimal={0} maxDecimal={9999.99} x={882} y={605} width={80} fontSize={14}/>
                        <DocumentField innerRef={fieldRefs[20]} stateProps={new ControlStateProps('additionalCountyWitholding', wh4)} type='number' minDecimal={0} maxDecimal={9999.99} x={882} y={626} width={80} fontSize={14}/>

                        <SubscriberWrapper stateProps={new ControlStateProps(null, wh4, null, 'wh4fields')} render={() => {
                            return (
                                <>
                                    <span style={{position: 'absolute', left: 909, top: 494}}>{wh4.subtotalAgeBlind()}</span>
                                    <span style={{position: 'absolute', left: 909, top: 518}}>{wh4.totalExemptions()}</span>
                                </>
                            )
                        }}/>

                        <DocumentSignatureButton innerRef={fieldRefs[21]} signature={signature} stateProps={new ControlStateProps('signed', wh4, Validation.true)} x={210} y={664} width={110} height={20}/>
                        <SubscriberWrapper stateProps={new ControlStateProps('signed', wh4, null, 'wrapper for date')} render={() => {
                            return (!wh4.signed ? '' : 
                            <span style={{position: 'absolute', left: 815, top: 662}}>{moment().format('MM/DD/YYYY')}</span>
                            )
                        }}/>
                    </div>
                    <div style={{position: 'relative', marginLeft: 'auto', marginRight: 'auto'}}>
                        <img src={WH4Page2} style={{width: 1100, height: 850}}/>
                    </div>
                </div>
            </Modal.Body>
            <DocumentWizard document={wh4} fieldRefs={fieldRefs} refCount={22} onSubmit={handleSubmit} zoom={zoom} setZoom={setZoom}/>
        </>
    )
}
