/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import {  useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Page, pdfjs, Document } from 'react-pdf';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import CustomButton from '../../components/CustomButton';
import { acknowledgeCompanyDocument } from '../../services/EmployeeOnboardingService';
import { AppGlobals } from '../../App';
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

export default function CompanyDocumentModal({onboardingToken, document, onAcknowledge}) {
    const [numPages, setNumPages] = useState(0);
    const [isSubmitting, setIsSubmitting] = useState(false);


    const handleSubmit = async () => {
        setIsSubmitting(true);
        const response = await acknowledgeCompanyDocument(onboardingToken, document.fileIdentifier ?? document.uid);
        if (response.status === 200) {
            onAcknowledge();
        } else {
            AppGlobals.alert('danger', response.message);
        }
        setIsSubmitting(false);
    }



    const options = {
        cMapUrl: 'cmaps/',
        cMapPacked: true,
        standardFontDataUrl: 'standard_fonts/', 
    };
    
    function onDocumentLoadSuccess({ numPages: nextNumPages }) {
        setNumPages(nextNumPages);
    }

    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>{document.documentName}</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{overflowX: 'auto', display: 'flex', flexDirection: 'column', backgroundColor: 'lightgray'}}>
                <div style={{marginLeft: 'auto', marginRight: 'auto'}}>
                    <Document file={document.base64} onLoadSuccess={onDocumentLoadSuccess} options={options} renderMode='canvas'> 
                        {Array.from(new Array(numPages), (el, index) => (
                            <Page key={`page_${index + 1}`} pageNumber={index + 1} />
                        ))}
                    </Document>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <CustomButton onClick={handleSubmit} isLoading={isSubmitting}>
                    <span>I Have Read And Acknowledge This Document: <b>{document.documentName}</b></span>
                </CustomButton>
            </Modal.Footer>
        </>
    )
}