/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import moment from "moment";
import Decoder from "../../../../../decoding";
import Address from "../../../../../models/Address";
import StateObject from "../../../../../models/State/StateObject"
import { addressToString, base64PdfToBase64Images, validateDecimal } from "../../../../../tools";

export default class REV419 extends StateObject {
    employeeSubmission;
    yearLast2;
    name;
    ssn;
    phoneNumber;
    streetAddress;
    city;
    state;
    zipCode;
    taxYear;
    section2a;
    section2b;
    section2c;
    reciprocalState;
    residentOfState;
    signed;
    // EMPLOYER
    employerName;
    ein;
    employerAddress;
    employerPhoneNumber;
    employerCity;
    employerState;
    employerZipCode;
    employeeCompensation;

    constructor(
        employeeSubmission,
        yearLast2,
        name,
        ssn,
        phoneNumber,
        streetAddress,
        city,
        state,
        zipCode,
        taxYear,
        section2a,
        section2b,
        section2c,
        reciprocalState,
        residentOfState,
        signed,
        employerName,
        ein,
        employerAddress,
        employerPhoneNumber,
        employerCity,
        employerState,
        employerZipCode,
        employeeCompensation
    ){
        super();
        this.employeeSubmission = employeeSubmission;
        this.yearLast2 = yearLast2;
        this.name = name;
        this.ssn = ssn;
        this.phoneNumber = phoneNumber;
        this.streetAddress = streetAddress;
        this.city = city;
        this.state = state;
        this.zipCode = zipCode;
        this.taxYear = taxYear;
        this.section2a = section2a;
        this.section2b = section2b;
        this.section2c = section2c;
        this.reciprocalState = reciprocalState;
        this.residentOfState = residentOfState;
        this.signed = signed;
        this.employerName = employerName;
        this.ein = ein;
        this.employerAddress = employerAddress;
        this.employerPhoneNumber = employerPhoneNumber;
        this.employerCity = employerCity;
        this.employerState = employerState;
        this.employerZipCode = employerZipCode;
        this.employeeCompensation = employeeCompensation;
    }

    static initDefault(){
        return new REV419(
            [],
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            false,
            false,
            false,
            undefined,
            '',
            false,
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
        )
    }

    static async initFromData(document, userData, companyData, isEmployer){
        const firstName = userData.firstName ?? '';
        const middleInitial = userData.middleName?.[0] ? `${userData.middleName?.[0]} ` : '';
        const lastName = userData.lastName ?? '';
        const phoneNumber = userData.phoneNumber ?? '';
        const userAddress = Address.decodeNonStrict(userData.address);
        const companyAddress = Address.decodeNonStrict(companyData.address);
        const companyName = companyData.companyName ?? companyData.name ?? '';
        const ein = companyData.ein ?? '';

        let employeeSubmission = [];
        if (isEmployer) {
            employeeSubmission = await base64PdfToBase64Images(document.employeeSubmission, 1173);
        }

        return new REV419(
            employeeSubmission,
            document.yearLast2 ?? moment().format('YY'),
            document.name ?? `${firstName} ${middleInitial}${lastName}`,
            document.ssn ?? '',
            document.phoneNumber ?? phoneNumber,
            document.streetAddress ?? `${userAddress.thoroughfare} ${userAddress.premise}`,
            document.city ?? userAddress.locality,
            document.state ?? userAddress.administrativeArea,
            document.zipCode ?? userAddress.postalCode,
            document.taxYear ?? '',
            document.section2a ?? false,
            document.section2b ?? false,
            document.section2c ?? false,
            document.reciprocalState !== null ? document.reciprocalState : undefined,
            document.residentOfState ?? '',
            false,  
            document.employerName ?? companyName,
            document.ein ?? ein,
            document.employerAddress ?? `${companyAddress.thoroughfare} ${companyAddress.premise}`,
            document.employerPhoneNumber ?? companyData.phoneNumber ?? '',
            document.employerCity ?? companyAddress.locality,
            document.employerState ?? companyAddress.administrativeArea,
            document.employerZipCode ?? companyAddress.postalCode,
            document.employeeCompensation ?? ''
        )
    }

    encode(document, base64){
        return {
            ...document,
            base64: base64,
            yearLast2: this.yearLast2,
            name: this.name,
            ssn: this.ssn,
            phoneNumber: this.phoneNumber,
            streetAddress: this.streetAddress,
            city: this.city,
            state: this.state,
            zipCode: this.zipCode,
            taxYear: this.taxYear,
            section2a: this.section2a,
            section2b: this.section2b,
            section2c: this.section2c,
            reciprocalState: this.reciprocalState,
            residentOfState: this.residentOfState,
            employerName: this.employerName,
            ein: this.ein,
            employerAddress: this.employerAddress,
            employerPhoneNumber: this.employerPhoneNumber,
            employerCity: this.employerCity,
            employerState: this.employerState,
            employerZipCode: this.employerZipCode,
            employeeCompensation: validateDecimal(this.employeeCompensation),

        }
    }
}