/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import  { useEffect, useRef, useState } from 'react';

import I9Page1 from '../../../../assets/I9-1.png';
import I9Page2 from '../../../../assets/I9-2.png';
import { Modal } from 'react-bootstrap';
import ControlStateProps from '../../../../models/State/ControlStateProps';
import { Validation } from '../../../../validation';
import moment from 'moment';
import SubscriberWrapper from '../../../../components/SubscriberWrapper';
import DocumentWizard from '../../DocumentWizard';
import DocumentSignatureButton from '../../DocumentSignatureButton';
import DocumentField from '../../DocumentField';
import DocumentRadioCheck from '../../DocumentRadioCheck';
import DocumentCheckbox from '../../DocumentCheckbox';
import I9Pdf from './I9Pdf';
import { AppGlobals } from '../../../../App';
import { reactPdfToBase64 } from '../../../../tools';
import { submitDocument } from '../../../../services/EmployeeOnboardingService';
import I9 from './I9';
import DocumentFileControl from '../../DocumentFileControl';
import CustomRadio from '../../../../components/controls/CustomRadio';
import LoadingWrapper from '../../../../components/LoadingWrapper';

export default function I9Form({document, userData, companyData, startDate, isEmployer, signature, hideModal, ssn, dl, onboardingToken, updateDocumentToCompleted}) {
    const [isLoading, setIsLoading] = useState(false);
    const [i9, setI9] = useState(I9.initDefault());
    const [zoom, setZoom] = useState(1);

    const fieldRefs = [
        useRef(), useRef(), useRef(), useRef(), useRef(), 
        useRef(), useRef(), useRef(), useRef(), useRef(), 
        useRef(), useRef(), useRef(), useRef(), useRef(), 
        useRef(), useRef(), useRef(), useRef(), useRef(), 
        useRef(), useRef(), useRef(), useRef(), useRef(), 
        useRef(), useRef(), useRef(), useRef(), useRef(),
    ]

    useEffect(() => {
        loadData();
    }, []);

    const loadData = async () => {
        setIsLoading(true);
        setI9(await I9.initFromData(document, userData, companyData, startDate, isEmployer));
        setIsLoading(false);
    }

    const handleSubmit = async () => {
        const pdf = <I9Pdf signature={signature} i9={i9} isEmployer={isEmployer} ssn={ssn} dl={dl}/>;
        const base64 = await reactPdfToBase64(pdf);
        if(base64){
            const response = await submitDocument(i9.encode(document, base64), onboardingToken);
            if (response.status === 200) {
                updateDocumentToCompleted('i9', base64);
                hideModal();
                AppGlobals.alert('success', response.message);
            } else {
                AppGlobals.alert('danger', response.message);
            }
        }
    }

    function handleToggleUploadType(type){
        if(type === 'a'){
            i9.removeValidation('bDocumentTitle');
            i9.removeValidation('bIssuingAuthority');
            i9.removeValidation('bDocument');
            i9.removeValidation('cDocumentTitle');
            i9.removeValidation('cIssuingAuthority');
            i9.removeValidation('cDocument');
        }else{
            i9.removeValidation('aDocumentTitle');
            i9.removeValidation('aIssuingAuthority');
            i9.removeValidation('aDocument');
        }

        // i9.setState('uploadType', type)
    }

    const pageCount = isEmployer ? i9.employeeSubmission.length : 2;

    const employeeAttachmentPages = i9.employeeSubmission.slice(2).map((element, index) => {
        return (
            <div key={index} style={{position: 'relative', marginLeft: 'auto', marginRight: 'auto'}}>
                <img src={element} style={{width: 850, height: 1100}}/>
            </div> 
        )
    })

    return (
        <>
            <Modal.Header closeButton/>
            <Modal.Body style={{padding: 0, overflowX: 'scroll', backgroundColor: 'lightgray'}}>
                <LoadingWrapper isLoading={isLoading}>
                    <div style={{transform: `scale(${zoom})`, display: 'flex', flexDirection: 'column', justifyContent: 'center', gap: 12, height: ((1100 * pageCount) + (12 * (pageCount - 1)) + 16) * zoom}}>
                        <div style={{position: 'relative', marginLeft: 'auto', marginRight: 'auto'}}>
                            <img src={isEmployer ? i9.employeeSubmission[0] ?? I9Page1 :I9Page1} style={{width: 850, height: 1100}}/>
                            {!isEmployer ? 
                                //EMPLOYEE
                            <>
                                <DocumentField innerRef={fieldRefs[0]} stateProps={new ControlStateProps('lastName', i9, Validation.nonEmptyString)} maxLength={20} x={56} y={239} width={214}/>
                                <DocumentField innerRef={fieldRefs[1]} stateProps={new ControlStateProps('firstName', i9, Validation.nonEmptyString)} maxLength={20} x={281} y={239} width={190}/>
                                <DocumentField innerRef={fieldRefs[2]} stateProps={new ControlStateProps('middleInitial', i9)} maxLength={1} x={480} y={239} width={90}/>
                                <DocumentField innerRef={fieldRefs[3]} stateProps={new ControlStateProps('otherLastNamesUsed', i9)} maxLength={28} x={580} y={239} width={216}/>
                                
                                <DocumentField innerRef={fieldRefs[4]} stateProps={new ControlStateProps('streetAddress', i9, Validation.nonEmptyString)} maxLength={30} x={56} y={274} width={258}/>
                                <DocumentField innerRef={fieldRefs[5]} stateProps={new ControlStateProps('aptNumber', i9)} maxLength={10} x={322} y={274} width={90}/>
                                <DocumentField innerRef={fieldRefs[6]} stateProps={new ControlStateProps('city', i9, Validation.nonEmptyString)} maxLength={20} x={420} y={274} width={210}/>
                                <DocumentField innerRef={fieldRefs[7]} stateProps={new ControlStateProps('state', i9, Validation.nonEmptyString)} maxLength={2} x={638} y={274} width={58}/>
                                <DocumentField innerRef={fieldRefs[8]} stateProps={new ControlStateProps('zipCode', i9, Validation.nonEmptyString)} maxLength={5} type='number' x={706} y={274} width={90}/>
                                <DocumentField innerRef={fieldRefs[9]} stateProps={new ControlStateProps('dateOfBirth', i9, Validation.nonEmptyString)} x={56} y={312} width={140} type='date'/>
                                <DocumentField innerRef={fieldRefs[10]} stateProps={new ControlStateProps('ssn', i9, Validation.ssn)} x={206} y={312} width={150} type='number' maxLength={9}/>
                                <DocumentField innerRef={fieldRefs[11]} stateProps={new ControlStateProps('emailAddress', i9, Validation.nonEmptyString)} maxLength={30} x={364} y={312} width={258}/>
                                <DocumentField innerRef={fieldRefs[12]} stateProps={new ControlStateProps('phoneNumber', i9, Validation.phoneNumber)} maxLength={10} x={630} y={312} width={166}/>

                                <DocumentRadioCheck innerRef={fieldRefs[13]} value={0} stateProps={new ControlStateProps('status', i9, Validation.nonEmptyString, 'status0')} x={252} y={360}/>
                                <DocumentRadioCheck innerRef={fieldRefs[14]} value={1} stateProps={new ControlStateProps('status', i9, Validation.nonEmptyString, 'status1')} x={252} y={377}/>
                                <DocumentRadioCheck innerRef={fieldRefs[15]} value={2} stateProps={new ControlStateProps('status', i9, Validation.nonEmptyString, 'status2')} x={252} y={393}/>
                                <DocumentRadioCheck innerRef={fieldRefs[16]} value={3} stateProps={new ControlStateProps('status', i9, Validation.nonEmptyString, 'status3')} x={252} y={410}/>

                                <DocumentField innerRef={fieldRefs[17]} stateProps={new ControlStateProps('uscisANumber', i9)} maxLength={15} x={541} y={391} width={259} fontSize={12}/>
                                <DocumentField innerRef={fieldRefs[18]} stateProps={new ControlStateProps('authorizedToWorkUntil', i9)} x={716} y={412} width={82} type='date' fontSize={10}/>
                                <DocumentField innerRef={fieldRefs[19]} stateProps={new ControlStateProps('uscisANumberForSection4', i9)} maxLength={15} x={250} y={466} width={116} fontSize={12}/>
                                <DocumentField innerRef={fieldRefs[20]} stateProps={new ControlStateProps('formI94Number', i9)} type='number' maxLength={11} x={384} y={466} width={149} fontSize={12}/>
                                <DocumentField innerRef={fieldRefs[21]} stateProps={new ControlStateProps('foreignPassportNumberAndCountry', i9)} maxLength={45} x={550} y={466} width={250} fontSize={12}/>

                                <DocumentSignatureButton innerRef={fieldRefs[22]} signature={signature} stateProps={new ControlStateProps('signed', i9, Validation.true)} x={170} y={483} width={94} height={32}/>
                                <SubscriberWrapper stateProps={new ControlStateProps('signed', i9, null, 'wrapper for date')} render={() => {
                                    return (!i9.signed ? '' : 
                                        <span style={{position: 'absolute', left: 518, top: 494}}>{moment().format('MM/DD/YYYY')}</span>
                                    )
                                }}/>
                            </>
                        : 
                                //EMPLOYER
                            <>
                                <DocumentField innerRef={fieldRefs[0]} stateProps={new ControlStateProps('aDocumentTitle', i9)} maxLength={40} x={178} y={604} width={185} fontSize={13}/>
                                <DocumentField innerRef={fieldRefs[1]} stateProps={new ControlStateProps('aIssuingAuthority', i9)} maxLength={40} x={178} y={630} width={185} fontSize={13}/>
                                <DocumentField innerRef={fieldRefs[2]} stateProps={new ControlStateProps('aDocumentNumber', i9)} maxLength={20} x={178} y={656} width={185} fontSize={13}/>
                                <DocumentField innerRef={fieldRefs[3]} stateProps={new ControlStateProps('aExpirationDate', i9)} type='date' x={178} y={681} width={185} fontSize={13}/>


                                <DocumentField innerRef={fieldRefs[4]} stateProps={new ControlStateProps('bDocumentTitle', i9)} maxLength={40} x={390} y={604} width={185} fontSize={13}/>
                                <DocumentField innerRef={fieldRefs[5]} stateProps={new ControlStateProps('bIssuingAuthority', i9)} maxLength={40} x={390} y={630} width={185} fontSize={13}/>
                                <DocumentField innerRef={fieldRefs[6]} stateProps={new ControlStateProps('bDocumentNumber', i9)} maxLength={20} x={390} y={656} width={185} fontSize={13}/>
                                <DocumentField innerRef={fieldRefs[7]} stateProps={new ControlStateProps('bExpirationDate', i9)} type='date' x={390} y={681} width={185} fontSize={13}/>

                                <DocumentField innerRef={fieldRefs[8]} stateProps={new ControlStateProps('cDocumentTitle', i9)} maxLength={40} x={590} y={604} width={200} fontSize={13}/>
                                <DocumentField innerRef={fieldRefs[9]} stateProps={new ControlStateProps('cIssuingAuthority', i9)} maxLength={40} x={590} y={630} width={200} fontSize={13}/>
                                <DocumentField innerRef={fieldRefs[10]} stateProps={new ControlStateProps('cDocumentNumber', i9)} maxLength={20} x={590} y={656} width={200} fontSize={13}/>
                                <DocumentField innerRef={fieldRefs[11]} stateProps={new ControlStateProps('cExpirationDate', i9)} type='date' x={590} y={681} width={200} fontSize={13}/>

                                <DocumentField innerRef={fieldRefs[12]} as='textarea' stateProps={new ControlStateProps('additionalInformation', i9)} width={425} x={370} y={720} fontSize={13} style={{height: 155, resize: 'none'}}/>
                                <DocumentCheckbox innerRef={fieldRefs[13]} stateProps={new ControlStateProps('alternateProcedure', i9)} x={372} y={883}/>
                            
                                <DocumentField innerRef={fieldRefs[14]} stateProps={new ControlStateProps('firstDayOfEmployment', i9, Validation.nonEmptyString)} type='date' x={637} y={925} width={160} fontSize={15}/>
                                <DocumentField innerRef={fieldRefs[15]} stateProps={new ControlStateProps('nameAndTitle', i9, Validation.nonEmptyString)} maxLength={60} x={53} y={970} width={340} fontSize={14}/>
                                <DocumentSignatureButton innerRef={fieldRefs[16]} signature={signature} stateProps={new ControlStateProps('signed', i9, Validation.true)} x={410} y={965} width={100} height={23}/>
                                <SubscriberWrapper stateProps={new ControlStateProps('signed', i9, null, 'wrapper for date')} render={() => {
                                    return (!i9.signed ? '' : 
                                        <span style={{position: 'absolute', left: 697, top: 965}}>{moment().format('MM/DD/YYYY')}</span>
                                    )
                                }}/>

                                <DocumentField innerRef={fieldRefs[17]} stateProps={new ControlStateProps('employerName', i9, Validation.nonEmptyString)} maxLength={50} x={53} y={1009} width={270} fontSize={13}/>
                                <DocumentField innerRef={fieldRefs[18]} stateProps={new ControlStateProps('fullAddress', i9, Validation.nonEmptyString)} maxLength={75} x={336} y={1009} width={460} fontSize={13}/>
                            </>
                        }
                        </div>
                        <div style={{position: 'relative', marginLeft: 'auto', marginRight: 'auto'}}>
                            <img src={I9Page2} style={{width: 850, height: 1100}}/>
                        </div> 
                        {isEmployer && employeeAttachmentPages}  
                    </div>
                </LoadingWrapper>
            {!isEmployer &&
                <>
                    <Modal.Header style={{backgroundColor: 'white'}}>
                        <Modal.Title >Document Upload</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{paddingBottom: 48, backgroundColor: 'white'}}>   
                        <CustomRadio innerRef={fieldRefs[23]} style={{marginBottom: 36}} title='Choose upload type' items={{'a': 'Upload Document From List A', 'bc': 'Upload Documents From List B And C'}} stateProps={new ControlStateProps('uploadType', i9)}  setSelection={(value)=>handleToggleUploadType(value)}/>
                        <SubscriberWrapper stateProps={new ControlStateProps('uploadType', i9, null, 'uploadTypeFori9')} render={()=>{
                            return (
                                <>
                                    { i9.uploadType === 'a' &&
                                        <div>
                                            <p style={{textAlign: 'center', fontWeight: 'bold', marginTop: 12, marginBottom: 8}}>Please Upload Your List A Document Below</p>
                                            <DocumentFileControl defaultLabel='Submitted List A Document' innerRef={fieldRefs[24]} stateProps={new ControlStateProps('aDocument', i9)} width={'100%'} type='file'/>
                                        </div>
                                    } 
                                    { i9.uploadType === 'bc' &&
                                    <div style={{display: 'flex', justifyContent: 'space-around', gap: 36}}>
                                            <div>
                                                <p style={{textAlign: 'center', fontWeight: 'bold', marginTop: 12, marginBottom: 8}}>Please Upload Your List B Document Below</p>
                                                <DocumentFileControl defaultLabel='Submitted List B Document' innerRef={fieldRefs[24]} stateProps={new ControlStateProps('bDocument', i9)} width={'100%'} type='file'/>
                                            </div>
                                            <div>
                                                <p style={{textAlign: 'center', fontWeight: 'bold', marginTop: 12, marginBottom: 8}}>Please Upload Your List C Document Below</p>
                                                <DocumentFileControl defaultLabel='Submitted List C Document' innerRef={fieldRefs[25]} stateProps={new ControlStateProps('cDocument', i9)} width={'100%'} type='file'/>
                                            </div>
                                        </div>
                                    } 
                                </>
                            )
                        }}/>
                    </Modal.Body>
                </>
            }
            </Modal.Body>
            <SubscriberWrapper stateProps={new ControlStateProps('uploadType', i9, null, 'wizard')} render={()=>{
                return (
                    <DocumentWizard document={i9} fieldRefs={fieldRefs} refCount={isEmployer ? 19 : i9.uploadType === '' ? 24 : i9.uploadType === 'a' ? 25 : 26} zoom={zoom} setZoom={setZoom} onSubmit={handleSubmit}/>
                )
            }}/>
            </>
            )
        }
        