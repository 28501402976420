/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import moment from "moment";
import { Document, Image, Page, Text, View } from "@react-pdf/renderer";
import { pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

const PAGE_HEIGHT = 792;
const PAGE_WIDTH = 612;

function clamp(value, min, max) {
  return Math.max(min, Math.min(max, value));
}

export function CustomDocumentPdf({
  signature,
  signatureBoxes,
  dateBoxes,
  pngImages,
}) {
  return (
    <Document>
      {pngImages.map((pngImage, pageIndex) => {
        const filteredDatesBoxes = dateBoxes.filter((dateBox) => {
          return Math.floor(Number(dateBox.y - 16 * pageIndex) / PAGE_HEIGHT) === pageIndex;
        });
        const filteredSignatureBoxes = signatureBoxes.filter((signatureBox) => {
          return Math.floor(Number(signatureBox.y - 16 * pageIndex) / PAGE_HEIGHT) === pageIndex;
        });

        const dateBoxElements = filteredDatesBoxes.map((dateBox, dateBoxIndex) => {
          const rawY = Number(dateBox.y) - pageIndex * PAGE_HEIGHT - 16 * pageIndex;
          const rawX = Number(dateBox.x);

          const style = {
            width: clamp(Number(dateBox.width), 0, PAGE_WIDTH),
            height: clamp(Number(dateBox.height), 0, PAGE_HEIGHT),
            left: clamp(rawX, 0, PAGE_WIDTH),
            top: clamp(rawY, 0, PAGE_HEIGHT),
            position: "absolute",
          };

          if (style.top + style.height > PAGE_HEIGHT || style.left + style.width > PAGE_WIDTH) {
            return null;
          }
          const fontSize = Math.min(style.width, style.height) * 0.4;

          return (
            <View key={dateBoxIndex} style={style}>
              <Text style={{fontSize: fontSize, textAlign: 'center'}}>
                {moment().format("MM/DD/YYYY")}
              </Text>
            </View>
          );
        });

        const signatureBoxElements = filteredSignatureBoxes.map(
          (signatureBox, signatureBoxIndex) => {
            const rawY = Number(signatureBox.y) - pageIndex * PAGE_HEIGHT - 16 * pageIndex;
            const rawX = Number(signatureBox.x);

            const style = {
              width: clamp(Number(signatureBox.width), 0, PAGE_WIDTH),
              height: clamp(Number(signatureBox.height), 0, PAGE_HEIGHT),
              left: clamp(rawX, 0, PAGE_WIDTH),
              top: clamp(rawY, 0, PAGE_HEIGHT),
              position: "absolute",
            };

            if (style.top + style.height > PAGE_HEIGHT || style.left + style.width > PAGE_WIDTH) {
              return null;
            }

            return (
              <Image key={signatureBoxIndex} style={style} src={signature.image} />
            );
          }
        );

        return (
          <Page key={pageIndex} size="LETTER" style={{ position: "relative" }}>
            <Image src={pngImage} style={{ width: "100%", height: "100%" }} />
            {dateBoxElements}
            {signatureBoxElements}
          </Page>
        );
      })}
    </Document>
  );
}