/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useEffect, useRef, useState } from 'react';
import F8850Page1 from '../../../../assets/F8850-1.png';
import F8850Page2 from '../../../../assets/F8850-2.png';
import { Button, Modal, useAccordionButton } from 'react-bootstrap';

import F8850Pdf from './F8850Pdf';
import { AppGlobals } from '../../../../App';
import { reactPdfToBase64 } from '../../../../tools';
import { submitDocument } from '../../../../services/EmployeeOnboardingService';
import DocumentWizard from '../../DocumentWizard';
import ControlStateProps from '../../../../models/State/ControlStateProps';
import DocumentField from '../../DocumentField';
import { Validation } from '../../../../validation';
import DocumentCheckbox from '../../DocumentCheckbox';
import DocumentSignatureButton from '../../DocumentSignatureButton';
import SubscriberWrapper from '../../../../components/SubscriberWrapper';
import moment from 'moment';
import F8850 from './F8850';
import LoadingWrapper from '../../../../components/LoadingWrapper';

export default function F8850Form({document, userData, companyData, signature, isEmployer, hideModal, onboardingToken, updateDocumentToCompleted}) {
    const [isLoading, setIsLoading] = useState(false);
    const [f8850, setF8850] = useState(F8850.initDefault());
    const [zoom, setZoom] = useState(1);

    const fieldRefs = [
        useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef()
    ]

    useEffect(() => {
        loadData();
    }, []);

    const loadData = async () => {
        setIsLoading(true);
        setF8850(await F8850.initFromData(document, userData, companyData, isEmployer));
        setIsLoading(false);
    }

    const handleSubmit = async () => {
        const pdf = <F8850Pdf signature={signature} f8850={f8850} isEmployer={isEmployer}/>;
        const base64 = await reactPdfToBase64(pdf);
        if(base64){
            const response = await submitDocument(f8850.encode(document, base64), onboardingToken);
            if (response.status === 200) {
                updateDocumentToCompleted('f8850', base64)
                hideModal();
                AppGlobals.alert('success', response.message);
            } else {
                AppGlobals.alert('danger', response.message);
            }
        }
    }

    return (
        <>
            <Modal.Header closeButton>
                
            </Modal.Header>
            <Modal.Body style={{padding: 0, overflowX: 'scroll', backgroundColor: 'lightgray'}}>
                <LoadingWrapper isLoading={isLoading}>
                    <div style={{transform: `scale(${zoom})`, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: 12, height: ((1000 * 2) + 12 + 16) * zoom}}>
                        <div style={{position: 'relative', marginLeft: 'auto', marginRight: 'auto'}}>
                            <img src={isEmployer ? f8850.employeeSubmission[0] ?? F8850Page1 : F8850Page1} style={{width: 780, height: 1000}}/>
                            {!isEmployer ?
                                <>
                                    <DocumentField innerRef={fieldRefs[0]} stateProps={new ControlStateProps('name', f8850, Validation.nonEmptyString)} maxLength={50} x={111} y={132} width={320} fontSize={14}/>
                                    <DocumentField innerRef={fieldRefs[1]} stateProps={new ControlStateProps('ssn', f8850, Validation.ssn)} x={595} y={132} width={140} fontSize={14} type='number' maxLength={9}/>

                                    <DocumentField innerRef={fieldRefs[2]} stateProps={new ControlStateProps('streetAddress', f8850, Validation.nonEmptyString)} maxLength={30} x={210} y={162} width={520} fontSize={14}/>
                                    <DocumentField innerRef={fieldRefs[3]} stateProps={new ControlStateProps('cityStateZipCode', f8850, Validation.nonEmptyString)} maxLength={75} x={227} y={193} width={500} fontSize={14}/>

                                    <DocumentField innerRef={fieldRefs[4]} stateProps={new ControlStateProps('county', f8850, Validation.nonEmptyString)} maxLength={20} x={90} y={223} width={300} fontSize={14}/>
                                    <DocumentField innerRef={fieldRefs[5]} stateProps={new ControlStateProps('phoneNumber', f8850, Validation.nonEmptyString)} type='number' maxLength={10} x={553} y={223} width={170} fontSize={14}/>
                                    <DocumentField innerRef={fieldRefs[6]} stateProps={new ControlStateProps('dateOfBirthIfUnder40', f8850)} type='date' x={394} y={254} width={110} fontSize={14}/>

                                    <DocumentCheckbox innerRef={fieldRefs[7]} stateProps={new ControlStateProps('checkbox1', f8850)} x={83} y={305}/>
                                    <DocumentCheckbox innerRef={fieldRefs[8]} stateProps={new ControlStateProps('checkbox2', f8850)} x={83} y={350}/>
                                    <DocumentCheckbox innerRef={fieldRefs[9]} stateProps={new ControlStateProps('checkbox3', f8850)} x={83} y={577}/>
                                    <DocumentCheckbox innerRef={fieldRefs[10]} stateProps={new ControlStateProps('checkbox4', f8850)} x={83} y={621}/>
                                    <DocumentCheckbox innerRef={fieldRefs[11]} stateProps={new ControlStateProps('checkbox5', f8850)} x={83} y={668}/>
                                    <DocumentCheckbox innerRef={fieldRefs[12]} stateProps={new ControlStateProps('checkbox6', f8850)} x={83} y={714}/>
                                    <DocumentCheckbox innerRef={fieldRefs[13]} stateProps={new ControlStateProps('checkbox7', f8850)} x={83} y={820}/>

                                    <DocumentSignatureButton innerRef={fieldRefs[14]} signature={signature} stateProps={new ControlStateProps('signed', f8850, Validation.true)} x={220} y={928} width={118} height={24}/>
                                    <SubscriberWrapper stateProps={new ControlStateProps('signed', f8850, null, 'wrapper for date')} render={() => {
                                        return (!f8850.signed ? '' : 
                                        <span style={{position: 'absolute', left: 635, top: 930}}>{moment().format('MM/DD/YYYY')}</span>
                                        )
                                }}/>
                                </>
                            :   <></>
                            }
                        </div>
                        <div style={{position: 'relative', marginLeft: 'auto', marginRight: 'auto'}}>
                            <img src={F8850Page2} style={{width: 780, height: 1000}}/>
                            {isEmployer ?
                                    //EMPLOYER
                                <>
                                    <DocumentField innerRef={fieldRefs[0]} stateProps={new ControlStateProps('employerName', f8850, Validation.nonEmptyString)} maxLength={45} x={145} y={86} width={220} fontSize={14}/>
                                    <DocumentField innerRef={fieldRefs[1]} stateProps={new ControlStateProps('employerPhoneNumber', f8850, Validation.nonEmptyString)} maxLength={10} type='number' x={458} y={86} width={110} fontSize={14}/>
                                    <DocumentField innerRef={fieldRefs[2]} stateProps={new ControlStateProps('ein', f8850, Validation.nonEmptyString)} maxLength={9} type='number' x={615} y={86} width={120} fontSize={14}/>

                                    <DocumentField innerRef={fieldRefs[3]} stateProps={new ControlStateProps('employerStreetAddress', f8850, Validation.nonEmptyString)} maxLength={50} x={130} y={117} width={550} fontSize={14}/>
                                    <DocumentField innerRef={fieldRefs[4]} stateProps={new ControlStateProps('employerCityStateZipCode', f8850, Validation.nonEmptyString)} maxLength={50} x={230} y={147} width={480} fontSize={14}/>

                                    <DocumentField innerRef={fieldRefs[5]} stateProps={new ControlStateProps('contactPerson', f8850)} maxLength={30} x={265} y={177} width={240} fontSize={14}/>
                                    <DocumentField innerRef={fieldRefs[6]} stateProps={new ControlStateProps('contactPhoneNumber', f8850)} type='number' maxLength={10} x={605} y={177} width={130} fontSize={14}/>
                                    <DocumentField innerRef={fieldRefs[7]} stateProps={new ControlStateProps('contactStreetAddress', f8850)} maxLength={50} x={130} y={207} width={560} fontSize={14}/>
                                    <DocumentField innerRef={fieldRefs[8]} stateProps={new ControlStateProps('contactCityStateZipCode', f8850)} maxLength={50} x={230} y={237} width={480} fontSize={14}/>
                                    <DocumentField innerRef={fieldRefs[9]} stateProps={new ControlStateProps('groupNumber', f8850)} type='number' minInt={0} maxInt={6} x={700} y={279} width={40} fontSize={18}/>

                                    <DocumentField innerRef={fieldRefs[9]} stateProps={new ControlStateProps('dateGaveInformation', f8850, Validation.nonEmptyString)} x={130} y={344} width={90} fontSize={11} type='date'/>
                                    <DocumentField innerRef={fieldRefs[10]} stateProps={new ControlStateProps('dateOfferedJob', f8850, Validation.nonEmptyString)} x={313} y={344} width={90} fontSize={11} type='date'/>
                                    <DocumentField innerRef={fieldRefs[11]} stateProps={new ControlStateProps('dateWasHired', f8850, Validation.nonEmptyString)} x={476} y={344} width={90} fontSize={11} type='date'/>
                                    <DocumentField innerRef={fieldRefs[12]} stateProps={new ControlStateProps('dateStartedJob', f8850, Validation.nonEmptyString)} x={644} y={344} width={90} fontSize={11} type='date'/>
                                    <DocumentField innerRef={fieldRefs[13]} stateProps={new ControlStateProps('employerTitle', f8850, Validation.nonEmptyString)} maxLength={30} x={432} y={450} width={190} fontSize={14}/>

                                    <DocumentSignatureButton innerRef={fieldRefs[14]} signature={signature} stateProps={new ControlStateProps('signed', f8850, Validation.true)} x={180} y={444} width={118} height={24}/>
                                    <SubscriberWrapper stateProps={new ControlStateProps('signed', f8850, null, 'wrapper for date')} render={() => {
                                        return (!f8850.signed ? '' : 
                                        <span style={{position: 'absolute', left: 659, top: 449, fontSize: 14}}>{moment().format('MM/DD/YYYY')}</span>
                                        )
                                        }}/>
                                </>
                            :   <></>
                            }
                        </div>
                    </div>
                </LoadingWrapper>
            </Modal.Body>
            <DocumentWizard document={f8850} fieldRefs={fieldRefs} refCount={15} onSubmit={handleSubmit} zoom={zoom} setZoom={setZoom}/>
        </>
    )
}
