/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import Decoder from "../../../../../decoding";
import Address from "../../../../../models/Address";
import StateObject from "../../../../../models/State/StateObject";
import { addressToString, validateDecimal, validateInteger } from "../../../../../tools";

export default class IT4 extends StateObject {
    name;
    ssn;
    address;
    schoolDistrictOfResidence;
    schoolDistrictNumber;
    section2Dependent;
    section2Single;
    section2NumberOfDependents;
    section2AdditionalWitholdings;
    section3FullYearResident;
    section3ResidentMilitary;
    section3NonResidentMilitary;
    section3NonResidentCivilian;
    section3Exempt;
    signed;

    constructor(
        name,
        ssn,
        address,
        schoolDistrictOfResidence,
        schoolDistrictNumber,
        section2Dependent,
        section2Single,
        section2NumberOfDependents,
        section2AdditionalWitholdings,
        section3FullYearResident,
        section3ResidentMilitary,
        section3NonResidentMilitary,
        section3NonResidentCivilian,
        section3Exempt,
        signed,
    ){
        super();
        this.name = name;
        this.ssn = ssn;
        this.address = address;
        this.schoolDistrictOfResidence = schoolDistrictOfResidence;
        this.schoolDistrictNumber = schoolDistrictNumber;
        this.section2Dependent = section2Dependent;
        this.section2Single = section2Single;
        this.section2NumberOfDependents = section2NumberOfDependents;
        this.section2AdditionalWitholdings = section2AdditionalWitholdings;
        this.section3FullYearResident = section3FullYearResident;
        this.section3ResidentMilitary = section3ResidentMilitary;
        this.section3NonResidentMilitary = section3NonResidentMilitary;
        this.section3NonResidentCivilian = section3NonResidentCivilian;
        this.section3Exempt = section3Exempt;
        this.signed = signed;
    }

    static initDefault(){
        return new IT4(
            [],
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            false,
            false,
            false,
            false,
            false,
            false,
        )
    }

    static initFromData(document, userData){
        const firstName = userData.firstName ?? '';
        const middleName = userData.middleName ? `${userData.middleName} ` : '';
        const lastName = userData.lastName ?? '';
        const userAddress = Address.decodeNonStrict(userData.address);

        return new IT4(
            document.name ?? `${firstName} ${middleName}${lastName}`,
            document.ssn ?? '',
            document.address ?? `${addressToString(userAddress)}`,
            document.schoolDistrictOfResidence ?? '',
            document.schoolDistrictNumber ?? '',
            document.section2Dependent ?? '',
            document.section2Single ?? '',
            document.section2NumberOfDependents ?? '',
            document.section2AdditionalWitholdings ?? '',   
            document.section3FullYearResident ?? false,
            document.section3ResidentMilitary ?? false,
            document.section3NonResidentMilitary ?? false,
            document.section3NonResidentCivilian ?? false,
            document.section3Exempt ?? false,
            false
        )
    }

    section2WitholdingExemptions(){
        return validateInteger(this.section2Dependent) + validateInteger(this.section2Single) + validateInteger(this.section2NumberOfDependents);
    }

    encode(document, base64){
        return {
            ...document,
            base64: base64,
            name: this.name,
            ssn: this.ssn,
            address: this.address,
            schoolDistrictOfResidence: this.schoolDistrictOfResidence ? this.schoolDistrictOfResidence : null,
            schoolDistrictNumber: this.schoolDistrictNumber ? validateInteger(this.schoolDistrictNumber) : null,
            section2Dependent: this.section2Dependent,
            section2Single: this.section2Single,
            section2NumberOfDependents: validateInteger(this.section2NumberOfDependents),
            section2AdditionalWitholdings: this.section2AdditionalWitholdings ? validateDecimal(this.section2AdditionalWitholdings) : null,
            section3FullYearResident: this.section3FullYearResident,
            section3ResidentMilitary: this.section3ResidentMilitary,
            section3NonResidentMilitary: this.section3NonResidentMilitary,
            section3NonResidentCivilian: this.section3NonResidentCivilian,
            section3Exempt: this.section3Exempt
        }
    }

}
