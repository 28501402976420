/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useEffect, useRef, useState } from 'react';
import K4Page1 from '../../../../../assets/K4-1.png';
import K4Page2 from '../../../../../assets/K4-2.png';
import { Button, Modal } from 'react-bootstrap';
import ControlStateProps from '../../../../../models/State/ControlStateProps';
import { pdf } from '@react-pdf/renderer';
import DocumentWizard from '../../../DocumentWizard';
import DocumentField from '../../../DocumentField';
import SubscriberWrapper from '../../../../../components/SubscriberWrapper';
import DocumentCheckbox from '../../../DocumentCheckbox';
import DocumentSignatureButton from '../../../DocumentSignatureButton';
import { Validation } from '../../../../../validation';
import moment from 'moment';
import K4Pdf from './K4Pdf';
import DocumentRadioCheck from '../../../DocumentRadioCheck';
import { AppGlobals } from '../../../../../App';
import { reactPdfToBase64 } from '../../../../../tools';
import { submitDocument } from '../../../../../services/EmployeeOnboardingService';
import K4 from './K4';

export default function K4Form({document, userData, signature, hideModal, onboardingToken, updateDocumentToCompleted}) {
    const [zoom, setZoom] = useState(1);
    const [k4, _] = useState(K4.initFromData(document, userData));

    const fieldRefs = [
        useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef(),
        useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef()
    ]

    const handleSubmit = async () => {
        const pdf = <K4Pdf signature={signature} k4={k4}/>;
        const base64 = await reactPdfToBase64(pdf);
        if(base64){
            const response = await submitDocument(k4.encode(document, base64), onboardingToken);
            if (response.status === 200) {
                updateDocumentToCompleted('k4', base64)
                hideModal();
                AppGlobals.alert('success', response.message);
            } else {
                AppGlobals.alert('danger', response.message);
            }
        }
    }

    return (
        <>
            <Modal.Header closeButton>
                
            </Modal.Header>
            <Modal.Body style={{padding: 0, overflowX: 'scroll', backgroundColor: 'lightgray'}}>
                <div style={{transform: `scale(${zoom})`, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: 12, height: ((1000 * 2) + (12) + 16) * zoom}}>
                    <div style={{position: 'relative', marginLeft: 'auto', marginRight: 'auto'}}>
                        <img src={K4Page1} style={{width: 750, height: 1000}}/>
                        <DocumentField innerRef={fieldRefs[1]} stateProps={new ControlStateProps('ssnFirst3', k4, Validation.nonEmptyString)} type='number' minInt={0} maxLength={3} x={100} y={115} width={50} fontSize={20}/>
                        <DocumentField innerRef={fieldRefs[2]} stateProps={new ControlStateProps('ssnMiddle2', k4, Validation.nonEmptyString)} type='number' minInt={0} maxLength={2} x={192} y={115} width={35} fontSize={20}/>
                        <DocumentField innerRef={fieldRefs[3]} stateProps={new ControlStateProps('ssnLast4', k4, Validation.nonEmptyString)} type='number' minInt={0} maxLength={4} x={260} y={115} width={70} fontSize={20}/>

                        <DocumentField innerRef={fieldRefs[4]} stateProps={new ControlStateProps('name', k4, Validation.nonEmptyString)} maxLength={45} x={40} y={165} width={340} fontSize={16}/>
                        <DocumentField innerRef={fieldRefs[5]} stateProps={new ControlStateProps('mailingAddress', k4, Validation.nonEmptyString)} maxLength={40} x={40} y={210} width={340} fontSize={16}/>
                        <DocumentField innerRef={fieldRefs[6]} stateProps={new ControlStateProps('city', k4, Validation.nonEmptyString)} maxLength={20} x={40} y={256} width={210} fontSize={16}/>
                        <DocumentField innerRef={fieldRefs[7]} stateProps={new ControlStateProps('state', k4, Validation.nonEmptyString)} maxLength={2} x={280} y={256} width={35} fontSize={16}/>
                        <DocumentField innerRef={fieldRefs[8]} stateProps={new ControlStateProps('zipCode', k4, Validation.nonEmptyString)} type='number' maxLength={5} x={337} y={256} width={60} fontSize={16}/>

                        <DocumentCheckbox innerRef={fieldRefs[9]} stateProps={new ControlStateProps('exemptBox1', k4)} x={32} y={357} width={11} height={11}/>
                        <DocumentCheckbox innerRef={fieldRefs[10]} stateProps={new ControlStateProps('exemptBox2', k4)} x={32} y={382} width={11} height={11}/>
                        <DocumentCheckbox innerRef={fieldRefs[11]} stateProps={new ControlStateProps('exemptBox3', k4)} x={32} y={405} width={11} height={11}/>
                        <DocumentCheckbox innerRef={fieldRefs[12]} stateProps={new ControlStateProps('exemptBox4', k4)} x={32} y={428} width={11} height={11}/>
                        <DocumentField innerRef={fieldRefs[13]} stateProps={new ControlStateProps('residentOfState', k4)} maxLength={15} x={520} y={370} width={155} fontSize={16}/>
                        <DocumentField innerRef={fieldRefs[14]} stateProps={new ControlStateProps('additionalWitholding', k4)} type='number' minDecimal={0} maxDecimal={9999.99} x={460} y={440} width={120} fontSize={16}/>

                        <DocumentSignatureButton innerRef={fieldRefs[15]} signature={signature} stateProps={new ControlStateProps('signed', k4, Validation.true)} x={130} y={525} width={118} height={26}/>
                        <SubscriberWrapper stateProps={new ControlStateProps('signed', k4, null, 'wrapper for date')} render={() => {
                            return (!k4.signed ? '' : 
                            <span style={{position: 'absolute', left: 500, top: 528}}>{moment().format('MM/DD/YYYY')}</span>
                            )
                        }}/>
                    </div>
                    <div style={{position: 'relative', marginLeft: 'auto', marginRight: 'auto'}}>
                        <img src={K4Page2} style={{width: 750, height: 1000}}/>
                        <DocumentRadioCheck innerRef={fieldRefs[16]} value={0} stateProps={new ControlStateProps('spouseMilitary', k4, Validation.none, 'spouseMilitary0')} x={608} y={167} width={11} height={11}/>
                        <DocumentRadioCheck value={1} stateProps={new ControlStateProps('spouseMilitary', k4, Validation.none, 'spouseMilitary1')} x={653} y={167} width={11} height={11}/>

                        <DocumentRadioCheck innerRef={fieldRefs[17]} value={0} stateProps={new ControlStateProps('notMilitary', k4, Validation.none, 'notMilitary0')} x={608} y={180} width={11} height={11}/>
                        <DocumentRadioCheck value={1} stateProps={new ControlStateProps('notMilitary', k4, Validation.none, 'notMilitary1')} x={653} y={180} width={11} height={11}/>

                        <DocumentRadioCheck innerRef={fieldRefs[18]} value={0} stateProps={new ControlStateProps('spouseMilitaryInKentucky', k4, Validation.none, 'spouseMilitaryInKentucky0')} x={608} y={208} width={11} height={11}/>
                        <DocumentRadioCheck value={1} stateProps={new ControlStateProps('spouseMilitaryInKentucky', k4, Validation.none, 'spouseMilitaryInKentucky1')} x={653} y={208} width={11} height={11}/>

                        <DocumentRadioCheck innerRef={fieldRefs[19]} value={0} stateProps={new ControlStateProps('liveSameAddress', k4, Validation.none, 'liveSameAddress0')} x={608} y={221} width={11} height={11}/>
                        <DocumentRadioCheck value={1} stateProps={new ControlStateProps('liveSameAddress', k4, Validation.none, 'liveSameAddress1')} x={653} y={221} width={11} height={11}/>

                        <DocumentRadioCheck innerRef={fieldRefs[20]} value={0} stateProps={new ControlStateProps('spouseDifferentDomicileState', k4, Validation.none, 'spouseDifferentDomicileStat0')} x={608} y={248} width={11} height={11}/>
                        <DocumentRadioCheck value={1} stateProps={new ControlStateProps('spouseDifferentDomicileState', k4, Validation.none, 'spouseDifferentDomicileState1')} x={653} y={248} width={11} height={11}/>
                        <DocumentField innerRef={fieldRefs[21]} stateProps={new ControlStateProps('differentDomicileStateCode', k4)} x={462} y={255} width={30} fontSize={12}/>

                        <DocumentRadioCheck innerRef={fieldRefs[22]} value={0} stateProps={new ControlStateProps('inKentuckyWithSpouse', k4, Validation.none, 'inKentuckyWithSpouse0')} x={608} y={275} width={11} height={11}/>
                        <DocumentRadioCheck value={1} stateProps={new ControlStateProps('inKentuckyWithSpouse', k4, Validation.none, 'inKentuckyWithSpouse1')} x={653} y={275} width={11} height={11}/>

                        <DocumentRadioCheck innerRef={fieldRefs[23]} value={0} stateProps={new ControlStateProps('resideInDifferentState', k4, null, 'resideInDifferentState0')} x={67} y={576} width={11} height={11}/>
                        <DocumentRadioCheck value={1} stateProps={new ControlStateProps('resideInDifferentState', k4, null, 'resideInDifferentState1')} x={155} y={576} width={11} height={11}/>
                        <DocumentRadioCheck value={2} stateProps={new ControlStateProps('resideInDifferentState', k4, null, 'resideInDifferentState2')} x={243} y={576} width={11} height={11}/>
                        <DocumentRadioCheck value={3} stateProps={new ControlStateProps('resideInDifferentState', k4, null, 'resideInDifferentState3')} x={331} y={576} width={11} height={11}/>
                        <DocumentRadioCheck value={4} stateProps={new ControlStateProps('resideInDifferentState', k4, null, 'resideInDifferentState4')} x={422} y={576} width={11} height={11}/>
                        <DocumentRadioCheck value={5} stateProps={new ControlStateProps('resideInDifferentState', k4, null, 'resideInDifferentState5')} x={67} y={590} width={11} height={11}/>
                        <DocumentRadioCheck value={6} stateProps={new ControlStateProps('resideInDifferentState', k4, null, 'resideInDifferentState6')} x={67} y={603} width={11} height={11}/>
                    </div>
                </div>
            </Modal.Body>
            <DocumentWizard document={k4} fieldRefs={fieldRefs} refCount={24} onSubmit={handleSubmit} zoom={zoom} setZoom={setZoom}/>
        </>
    )
}
