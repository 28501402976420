/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { useRef, useState } from 'react';

import { Modal } from 'react-bootstrap';
import ControlStateProps from '../../../../models/State/ControlStateProps';
import { Validation } from '../../../../validation';
import moment from 'moment';
import SubscriberWrapper from '../../../../components/SubscriberWrapper';
import DocumentWizard from '../../DocumentWizard';
import DocumentSignatureButton from '../../DocumentSignatureButton';
import { pdfjs } from 'react-pdf';
import DocumentField from '../../DocumentField';
import DocumentFileControl from '../../DocumentFileControl';
import DocumentRadioCheck from '../../DocumentRadioCheck';
import DirectDepositPdf from './DirectDepositPdf';
import { AppGlobals } from '../../../../App';
import { reactPdfToBase64 } from '../../../../tools';
import { submitDocument } from '../../../../services/EmployeeOnboardingService';
import DirectDeposit from './DirectDeposit';
import DirectDepositFormBackground from './DirectDepositFormBackground';
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

export default function DirectDepositForm({document, userData, companyData, signature, hideModal, onboardingToken, updateDocumentToCompleted}) {
    const [zoom, setZoom] = useState(1);

    const [dd, _] = useState(DirectDeposit.initFromData(document, userData, companyData));

    const fieldRefs = [useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef()];

    const handleSubmit = async () => {
        const pdf = <DirectDepositPdf signature={signature} dd={dd}/>;
        const base64 = await reactPdfToBase64(pdf);
        if(base64){
            const response = await submitDocument(dd.encode(document, base64), onboardingToken);
            if (response.status === 200) {
                updateDocumentToCompleted('dd', base64)
                hideModal();
                AppGlobals.alert('success', response.message);
            } else {
                AppGlobals.alert('danger', response.message);
            }
        }
    }

    return (
        <>
            <Modal.Header closeButton/>
            <Modal.Body style={{padding: 0, overflowX: 'scroll', backgroundColor: 'lightgray'}}>
                <div style={{transform: `scale(${zoom})`, display: 'flex', flexDirection: 'column', justifyContent: 'center', gap: 12, height: (1100 + 16) * zoom}}>
                    <div style={{position: 'relative', marginLeft: 'auto', marginRight: 'auto'}}>
                        <DirectDepositFormBackground dd={dd}/>
                        <DocumentField innerRef={fieldRefs[0]} stateProps={new ControlStateProps('employeeName', dd, Validation.nonEmptyString)} maxLength={50} x={77} y={182} width={228}/>
                        <DocumentField innerRef={fieldRefs[1]} stateProps={new ControlStateProps('email', dd, Validation.nonEmptyString)} maxLength={30} x={310} y={182} width={228}/>
                        <DocumentField innerRef={fieldRefs[2]} stateProps={new ControlStateProps('phoneNumber', dd, Validation.nonEmptyString)} maxLength={10}  x={543} y={182} width={228}/>
                        <DocumentField innerRef={fieldRefs[3]} stateProps={new ControlStateProps('fiName', dd, Validation.nonEmptyString)} x={77} maxLength={30} y={228} width={228}/>
                        <DocumentField innerRef={fieldRefs[4]} stateProps={new ControlStateProps('routingNumber', dd, Validation.nonEmptyString)} maxLength={9} x={310} y={228} width={228} type='number'/>
                        <DocumentField innerRef={fieldRefs[5]} stateProps={new ControlStateProps('accountNumber', dd, Validation.nonEmptyString)} maxLength={17} x={543} y={228} width={228} type='number'/>

                        <DocumentRadioCheck rhs innerRef={fieldRefs[6]} value={0} stateProps={new ControlStateProps('accountType', dd, Validation.notUndefined, 'accountType0')} x={660} y={259} width={15} height={15}/>
                        <DocumentRadioCheck rhs innerRef={fieldRefs[7]} value={1} stateProps={new ControlStateProps('accountType', dd, Validation.notUndefined, 'accountType1')} x={754} y={259} width={15} height={15}/>

                        <DocumentSignatureButton innerRef={fieldRefs[8]} signature={signature} stateProps={new ControlStateProps('signed', dd, Validation.true)} x={85} y={660}/>
                        <SubscriberWrapper stateProps={new ControlStateProps('signed', dd, null, 'wrapper for date')} render={() => {
                            return (!dd.signed ? '' : 
                                <span style={{position: 'absolute', left: 424, top: 680}}>{moment().format('MM/DD/YYYY')}</span>
                            )
                        }}/>

                        <span style={{position: 'absolute', left: 0, right: 0, top: 800, textAlign: 'center', fontWeight: 'bold'}}>Please upload your bank document below</span>
                        <DocumentFileControl innerRef={fieldRefs[9]} defaultLabel={'Submitted Bank Document'} stateProps={new ControlStateProps('attachment', dd, Validation.notUndefined)} x={200} y={840} width={450} type='file'/>
                    </div>
                </div>
            </Modal.Body>
            <DocumentWizard document={dd} fieldRefs={fieldRefs} refCount={10} zoom={zoom} setZoom={setZoom} onSubmit={handleSubmit}/>
        </>
    )
}
