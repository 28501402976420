/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import Decoder from "../../../../../decoding";
import Address from "../../../../../models/Address";
import StateObject from "../../../../../models/State/StateObject";
import { base64PdfToBase64Images, validateDecimal, validateInteger } from "../../../../../tools";

export default class MIW4 extends StateObject {
    employeeSubmission;
    ssn;
    dateOfBirth;
    name;
    driverOrStateId;
    homeAddress;
    city;
    state;
    zipCode;
    newEmployee;
    dateOfHire;
    numberOfExemptions;
    additionalWitholding;
    step8a;
    step8b;
    step8c;
    step8bExplanation;
    step8cResidentZone;
    signed;
    // Employer
    employerName;
    ein;
    employerAddress;
    employerCity;
    employerState;
    employerZipCode;        
    contactName;
    contactPhoneNumber;

    constructor(
        employeeSubmission,
        ssn,
        dateOfBirth,
        name,
        driverOrStateId,
        homeAddress,
        city,
        state,
        zipCode,
        newEmployee,
        dateOfHire,
        numberOfExemptions,
        additionalWitholding,
        step8a,
        step8b,
        step8c,
        step8bExplanation,
        step8cResidentZone,
        signed,
        employerName,
        ein,
        employerAddress,
        employerCity,
        employerState,
        employerZipCode,        
        contactName,
        contactPhoneNumber
    ){
        super();
        this.employeeSubmission = employeeSubmission;
        this.ssn = ssn;
        this.dateOfBirth = dateOfBirth;
        this.name = name;
        this.driverOrStateId = driverOrStateId;
        this.homeAddress = homeAddress;
        this.city = city;
        this.state = state;
        this.zipCode = zipCode;
        this.newEmployee = newEmployee;
        this.dateOfHire = dateOfHire;
        this.numberOfExemptions = numberOfExemptions;
        this.additionalWitholding = additionalWitholding;
        this.step8a = step8a;
        this.step8b = step8b;
        this.step8c = step8c;
        this.step8bExplanation = step8bExplanation;
        this.step8cResidentZone = step8cResidentZone;
        this.signed = signed;
        this.employerName = employerName;
        this.ein = ein;
        this.employerAddress = employerAddress;
        this.employerCity = employerCity;
        this.employerState = employerState;
        this.employerZipCode = employerZipCode;
        this.contactName = contactName;
        this.contactPhoneNumber = contactPhoneNumber;
    }

    static initDefault(){
        return new MIW4(
            [],
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            undefined,
            '',
            '',
            '',
            false,
            false,
            false,
            '',
            '',
            false,
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
        )
    }

    static async initFromData(document, userData, companyData, isEmployer){
        const firstName = userData.firstName ?? '';
        const middleInitial = userData.middleName?.[0] ? `${userData.middleName?.[0]} ` : '';
        const lastName = userData.lastName ?? '';
        const dateOfBirth = userData.dateOfBirth ?? '';
        const userAddress = Address.decodeNonStrict(userData.address);

        const companyAddress = Address.decodeNonStrict(companyData.address);
        const companyName = companyData.companyName ?? companyData.name ?? '';
        const ein = companyData.ein ?? '';

        let employeeSubmission = [];
        if (isEmployer) {
            employeeSubmission = await base64PdfToBase64Images(document.employeeSubmission, 1173);
        }

        return new MIW4(
            employeeSubmission,
            document.ssn ?? '',
            document.dateOfBirth ?? dateOfBirth,
            document.name ?? `${firstName} ${middleInitial}${lastName}`,
            document.driverOrStateId ?? '',
            document.homeAddress ?? `${userAddress?.thoroughfare} ${userAddress?.premise}`,
            document.city ?? userAddress?.locality,
            document.state ?? userAddress?.administrativeArea,
            document.zipCode ?? userAddress?.postalCode,
            document.newEmployee !== null ? document.newEmployee : undefined,
            document.dateOfHire ?? '',
            document.numberOfExemptions ?? '',
            document.additionalWitholding ?? '',
            document.step8a ?? false,
            document.step8b ?? false,
            document.step8c ?? false,
            document.step8bExplanation ?? '',
            document.step8cResidentZone ?? '',
            false,
            document.employerName ?? companyName,
            document.ein ?? ein,
            document.employerAddress ?? `${companyAddress.thoroughfare} ${companyAddress.premise}`,
            document.employerCity ?? companyAddress.locality,
            document.employerState ?? companyAddress.administrativeArea,
            document.employerZipCode ?? companyAddress.postalCode,
            document.contactName ?? '',
            document.contactPhoneNumber ?? ''
        )
    }

    encode(document, base64){
        return {
            ...document,
            base64: base64,
            ssn: this.ssn,
            dateOfBirth: this.dateOfBirth,
            name: this.name,
            homeAddress: this.homeAddress,
            city: this.city,
            state: this.state,
            zipCode: validateInteger(this.zipCode),
            driverOrStateId: this.driverOrStateId,
            newEmployee: this.newEmployee,
            dateOfHire: this.dateOfHire ? this.dateOfHire : null,
            numberOfExemptions: validateInteger(this.numberOfExemptions),
            additionalWitholding: this.additionalWitholding ? validateInteger(this.additionalWitholding) : null,
            step8a: this.step8a,  
            step8b: this.step8b,   
            step8c: this.step8c,
            step8bExplanation: this.step8bExplanation ? this.step8bExplanation : null,  
            step8cResidentZone: this.step8cResidentZone ? this.step8cResidentZone : null,
            employerName: this.employerName,
            ein: validateInteger(this.ein),
            employerAddress: this.employerAddress,
            employerCity: this.employerCity,
            employerState: this.employerState,
            employerZipCode: validateInteger(this.employerZipCode),
            contactName: this.contactName,
            contactPhoneNumber: this.contactPhoneNumber   
        }
    }
}
