/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import moment from 'moment';
import { addressToString, availabilityFromBinaryString, formatPhoneNumber, formatTime, usdFormatter, validateInteger } from '../../../../tools';
import { EmployeeTypes, PayTypes } from '../../../../enums';
import { Document, Page, Text, View} from "@react-pdf/renderer";

export function OfferLetterFormBackground({document, userData, companyData}) {

    const parseOfferLetter = (text) => {
        text = text.replaceAll('[CompanyName]', companyData.companyName ?? companyData.name);
        text = text.replaceAll('[EmployeeName]', `${userData.firstName} ${userData.lastName}`);
        if(document.createdDate !== null){
            text = text.replaceAll('[CreatedDate]', moment(document.createdDate).format('MMM D, YYYY'));
        }
        if(document.employeeType !== null){
            text = text.replaceAll('[EmployeeType]', EmployeeTypes[document.employeeType]);
        }
        if(document.startDate !== null){
            text = text.replaceAll('[StartDate]', moment(document.startDate).format('MMM D, YYYY'));
        }
        if(document.supervisorName !== null){
            text = text.replaceAll('[SupervisorName]', document.supervisorName);
        }
        if(document.terminalAddress !== null){
            text = text.replaceAll('[TerminalAddress]', addressToString(document.terminalAddress));
            text = text.replaceAll('[LocationAddress]', addressToString(document.terminalAddress));
        }
        if(document.startTime !== null){
            text = text.replaceAll('[StartTime]', formatTime(document.startTime));
        }
        if(document.payRate !== null && document.payType !== null){
            text = text.replaceAll('[Pay]', `${usdFormatter.format(document.payRate)} ${PayTypes[document.payType]}`);
        }
        if(document.stopBonusWeekdayThreshold !== null && document.stopBonusWeekendThreshold !== null){
            text = text.replaceAll('[StopBonusThresholds]', document.stopBonusWeekdayThreshold == document.stopBonusWeekendThreshold ? `${validateInteger(document.stopBonusWeekdayThreshold)} stops` : `${validateInteger(document.stopBonusWeekdayThreshold)}/${validateInteger(document.stopBonusWeekendThreshold)} (weekdays/weekends) stops`);
        }
        if(document.stopBonusWeekdayAmount !== null && document.stopBonusWeekendAmount !== null){
            text = text.replaceAll('[StopBonusAmounts]', document.stopBonusWeekdayAmount == document.stopBonusWeekendAmount ? usdFormatter.format(document.stopBonusWeekdayAmount) : `${usdFormatter.format(document.stopBonusWeekdayAmount)}/${usdFormatter.format(document.stopBonusWeekendAmount)} (weekdays/weekends)`);
        }
        if(document.expectedAvailability !== null){
            text = text.replaceAll('[ExpectedAvailability]', availabilityFromBinaryString(document.expectedAvailability));
        }
        if(document.dailyStartTime !== null){
            text = text.replaceAll('[DailyStartTime]', formatTime(document.dailyStartTime));
        }
        if(document.benefits !== null){
            text = text.replaceAll('[Benefits]', document.benefits ? document.benefits : 'There are currently no available benefits for this employer');
        }
        if(document.aoName !== null){
            text = text.replaceAll('[AOName]', document.aoName);
            text = text.replaceAll('[OfferLetterManagerName]', document.aoName);
        }
        if(document.aoPhoneNumber !== null){
            text = text.replaceAll('[AOPhoneNumber]', formatPhoneNumber(document.aoPhoneNumber));
            text = text.replaceAll('[OfferLetterManagerPhoneNumber]', formatPhoneNumber(document.aoPhoneNumber));
        }
        if(document.aoEmail !== null){
            text = text.replaceAll('[AOEmail]', document.aoEmail);
            text = text.replaceAll('[OfferLetterManagerEmail]', document.aoEmail);
        }
        return text;
    }

    return (
        <Document>
            <Page size="LETTER" style={{ padding: 50, display: 'flex', flexDirection: 'column' }}>
            <View style={{ flexGrow: 1 }}>
                <Text style={{ fontSize: 11.5 }}>{parseOfferLetter(document.content)}</Text>
            </View>
            <View style={{ height: 80 }} />
            <View style={{ display: 'flex', flexDirection: 'column', marginTop: 'auto' }} wrap={false}>
                <Text style={{ fontSize: 11.5, marginBottom: 60 }}>Please sign and date to confirm receipt of this document.</Text>
                <View style={{ display: 'flex', flexDirection: 'row', gap: 50, fontSize: 11.5 }}>
                <Text style={{ borderTop: '1px solid black', paddingLeft: 12, paddingRight: 50, paddingTop: 4 }}>Employee's Signature</Text>
                <Text style={{ borderTop: '1px solid black', paddingLeft: 12, paddingRight: 50, paddingTop: 4 }}>Date</Text>
                </View>
            </View>
            </Page>
        </Document>
    )
}